import React, {Component} from "react";
import axios from 'axios';

import './forgottenPassword.scss'
import { AUTH_URL } from "../../../urls";

class ForgottenPassword extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            waitResponse: false,
            resetSent: false,
        }
    }

    canSubmit() {
        return this.state.email.trim().length > 0 && !this.state.waitResponse
    }

    handleChangeEmail(event) {
        event.preventDefault();
        this.setState({
            email: event.target.value
        })
    }

    submitMail(event) {
        event.preventDefault();
        this.setState({
            waitResponse: true
        });
        axios.post(`${AUTH_URL}password/reset/`, {email: this.state.email}).then(res => {
            this.setState({
                resetSent: true,
                waitResponse: false
            })
        })
    }

    render() {
        const options = this.state.resetSent ? (
            <span className="info">Un mail de réinitialisation a été envoyé !</span>
        ) : (
                <form>
                    <div className="form-group">
                        <input type="email" placeholder="Quelle est ton adresse e-mail ?" value={this.state.email} onChange={this.handleChangeEmail.bind(this)}/>
                    </div>
                    <div className="submit-wrapper">
                        <button className={this.canSubmit() ? "" : "disabled"}
                                onClick={this.submitMail.bind(this)}>Envoyer
                        </button>
                    </div>
                </form>
        )

        return (
            <div className="forgotten-password">
                <h3>
                    Mot de passe oublié
                </h3>
                {options}
            </div>
        );
    }
}

export default ForgottenPassword;