import React, {Component} from "react";

import './admin.scss'
import Spinner1 from '../../generic/spinner/spinner1'
import axios from 'axios';
import { RESPOND_USER_VERIF } from "../../../../urls";
import {connect} from "react-redux";

class UserSection extends Component {

  constructor(props) {
    super(props)
    this.state = {
      waiting: false
    }
  }

  refuseConfirmationUser(event) {
    event.preventDefault()
    this.updateConfirmationUser(false)
  }

  acceptConfirmationUser(event) {
    event.preventDefault()
    this.updateConfirmationUser(true)
  }

  updateConfirmationUser(confirmUser) {
    let header = {
        "Content-Type": 'application/json',
        Authorization: this.props.user.token
    }
    this.setState({
      waiting: true
    })
    axios.post(`${RESPOND_USER_VERIF+this.props.member.id}/`, 
      { verified: confirmUser }, { headers: header }).then(() => {
        this.setState({
          waiting: false
        })
        this.props.getUsers()
      }).catch(error => {
          console.log(error);
      })
  }

  render () {
    const confirmActionOptions = this.state.waiting ? <Spinner1 className="spinner-gray"/> : (
      <div>
        <button onClick={this.refuseConfirmationUser.bind(this)} className="abord">Non</button>
        <button onClick={this.acceptConfirmationUser.bind(this)} className="validate ml-4">Oui</button>
      </div>
    )

    return <tr className="mb-2">
      <td>
          {this.props.member.username}
      </td>
      <td>
          {this.props.member.name}
      </td>
      <td>
          {this.props.member.firstname}
      </td>
      <td className="pt-2 pb-2">
        {confirmActionOptions}
      </td>
  </tr>
  }

}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(UserSection);
