import React, {Component} from "react";

import Carousel from './carousel'


import {connect} from 'react-redux'
import {DateHelper} from "../../../../utils/dateHelper";

const defaultBookingSlotNumber = 15

class CarouselContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookings: this.bookingSlots()
        }
    }

    bookingSlots() {
        // Get the current date
        const currentDate = new Date();
        let bookingSlot = DateHelper.getNearestBookingSlot(currentDate);
        let res = [bookingSlot]

        // Add booking slots in carousel
        for (let i = 0; i < defaultBookingSlotNumber; i++) {
            res.push(res[res.length - 1].getNextBookingSlot())
        }
        return res
    }

    render() {
        return (
            <Carousel bookings={this.state.bookings}/>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(CarouselContainer);