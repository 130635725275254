import React from "react";
import {Col, Row} from 'reactstrap';
import './admin.scss'
import Select from "../../generic/select/select";
import StringRenderer from "../../../utils/stringRenderer";
import Spinner1 from "../../generic/spinner/spinner1";
import Parser from "../../../utils/parsers";
import {DateHelper, HoursBookingSlots} from "../../../utils/dateHelper";
import connect from "react-redux/es/connect/connect";

type Props = {
    booking: any
};

class EditRegularBookingForm extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            dropdownActive: false,
            bookTypeValue: this.getBookTypeValue(props.bookTypeOptions, props.booking.book_type),
            bookDayValue: props.bookDayOptions[Parser.parseDjangoDate(props.booking.date_begin).getDay() - 1],
            bookingSlotValue: this.getBookingSlotValue(props.bookingSlotOptions, Parser.parseDjangoDate(props.booking.date_begin), Parser.parseDjangoDate(props.booking.date_end)),
            dateBegin: Parser.parseDjangoDate(props.booking.date_begin),
            dateEnd: Parser.parseDjangoDate(props.booking.date_end),
            dateBeginMin: Parser.parseDjangoDate(props.booking.date_begin),
            dateBeginMax: DateHelper.addDays(Parser.parseDjangoDate(props.booking.date_begin), 365),
            dateEndMin: DateHelper.addDays(Parser.parseDjangoDate(props.booking.date_begin), 14),
            dateEndMax: DateHelper.addDays(Parser.parseDjangoDate(props.booking.date_end), 365),
        }
    }

    getBookTypeValue(bookTypeOptions, book_type) {
        return bookTypeOptions.find(option => {
            return option.id === book_type
        })
    }

    getBookingSlotValue(options, dayBegin, dayEnd) {
        let index = HoursBookingSlots.findIndex(hourBookingSlot => {
            return hourBookingSlot.hourBegin.hour === dayBegin.getUTCHours() &&
                hourBookingSlot.hourEnd.hour === dayEnd.getUTCHours()
        })
        return options[index]
    }

    handleChangeBookType(newValue) {
        this.setState({
            bookTypeValue: newValue
        })
    }

    handleChangeBookDay(bookDay) {
        this.setState({
            bookDayValue: bookDay,
            dateBegin: DateHelper.getNearestNextDayDate(this.state.dateBegin, bookDay.day)
        })
    }

    handleChangeDateBegin(event) {
        event.preventDefault();
        let date = event.target.value;
        let reset = !date;
        let parsedDate;
        if (reset) parsedDate = new Date();
        else parsedDate = Parser.parseLimitedDashDate(date);
        if (parsedDate.getDay() === 0) DateHelper.addDays(parsedDate, 1);
        let correspondingDay = this.bookDayOptions().find(day => {
            return parsedDate.getDay() === day.day
        })
        this.setState({
            bookDayValue: correspondingDay,
            dateBegin: parsedDate,
            dateEnd: DateHelper.copyAddDays(parsedDate, 365),
            dateEndMin: DateHelper.copyAddDays(parsedDate, 14),
            dateEndMax: DateHelper.copyAddDays(parsedDate, 365),
        })
    }

    handleChangeDateEnd(event) {
        event.preventDefault();
        let date = event.target.value;
        let reset = !date;
        let parsedDate;
        if (reset) parsedDate = this.state.dateEndMax;
        else parsedDate = Parser.parseLimitedDashDate(date);
        this.setState({
            dateEnd: parsedDate
        })
    }

    handleChangeBookingSlot(bookingSlot) {
        this.setState({
            bookingSlotValue: bookingSlot
        })
    }

    toggleModifBooking(event) {
        event.preventDefault();
        this.props.toggleModifBooking()
    }

    sendModifBooking(event) {
        event.preventDefault();
        this.props.updateBooking(this.props.booking, this.state.bookTypeValue.id, this.state.dateBegin, this.state.dateEnd, this.state.bookingSlotValue.bookingSlot);
    }

    render() {
        const actionsOptions = this.props.waitRequestBooking ? (
            <Spinner1/>
        ) : (
            <div>
                <button className="abord mr-5" title="annuler" onClick={this.toggleModifBooking.bind(this)}>annuler
                </button>
                <button title="valider" className="validate" onClick={this.sendModifBooking.bind(this)}>ok</button>
            </div>
        )

        const bookerInfo = this.props.booking.band ? `${StringRenderer.bandRenderer(this.props.booking.band)} (Groupe)` : `${StringRenderer.userRenderer(this.props.booking.user)} (Membre)`;

        return (
            <Row>
                <Col>
                    <Select
                        onChange={this.handleChangeBookType.bind(this)}
                        lg={true}
                        options={this.props.bookTypeOptions}
                        value={this.state.bookTypeValue}/>
                </Col>
                <Col>
                    {bookerInfo}
                </Col>
                <Col>
                    <Select
                        onChange={this.handleChangeBookDay.bind(this)}
                        lg={true}
                        options={this.props.bookDayOptions}
                        value={this.state.bookDayValue}/>
                </Col>
                <Col>
                    <Select
                        onChange={this.handleChangeBookingSlot.bind(this)}
                        lg={true}
                        options={this.props.bookingSlotOptions}
                        value={this.state.bookingSlotValue}/>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <input type="date" min={DateHelper.renderDate(this.state.dateBeginMin).limitedDash}
                                   max={DateHelper.renderDate(this.state.dateBeginMax).limitedDash}
                                   onChange={this.handleChangeDateBegin.bind(this)}
                                   value={DateHelper.renderDate(this.state.dateBegin).limitedDash}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input type="date" min={DateHelper.renderDate(this.state.dateEndMin).limitedDash}
                                   max={DateHelper.renderDate(this.state.dateEndMax).limitedDash}
                                   onChange={this.handleChangeDateEnd.bind(this)}
                                   value={DateHelper.renderDate(this.state.dateEnd).limitedDash}/>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    {actionsOptions}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(EditRegularBookingForm);
