import React, { Component } from "react";

import './bookingPanel.scss'
import CarouselContainer from "./carousel/carouselContainer";

class BookingPanel extends Component {

    render() {
        return(
            <CarouselContainer/>
        )
    }
}

export default BookingPanel;