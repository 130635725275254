import React, {Component} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import './carousel.scss';
import {BookingSlot, DateHelper} from "../../../../utils/dateHelper";
import {connect} from 'react-redux';
import Select from "../../../generic/select/select";
import {BookingTypes} from "../../../apis/booking/booking";
import StringRenderer from "../../../../utils/stringRenderer";
import Modify from "../../../../../assets/images/modify.svg";
import Delete from "../../../../../assets/images/delete.svg";
import ModifyBookingModal from "../../../apis/booking/modifyBookingModal";
import {NavLink} from 'react-router-dom';

type Props = {
    bookingSlot: BookingSlot,
};

class Slide extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            booker: props.options[0],
            bookType: BookingTypes.training
        }
        this.handleChangeBookType = this.handleChangeBookType.bind(this);
        this.handleChangeBooker = this.handleChangeBooker.bind(this);
    }

    validateBooking(event) {
        event.preventDefault();
        this.props.validateBooking(this.state.booker, this.state.bookType)
    }

    handleChangeBooker(value) {
        this.setState({booker: value});
    }

    handleChangeBookType(value) {
        this.setState({bookType: value});
    }

    renderBooking() {
        let booking = this.props.booking;
        let content;
        if (!booking) {
            return {
                type: "free",
                stringType: "Libre",
            }
        }

        let bookOwner = booking.band ? <NavLink to={`/band/${booking.band.id}/`}>{StringRenderer.bandRenderer(booking.band)}</NavLink> : 
        <NavLink to={`/profile/${booking.user.id}/`}>{StringRenderer.userRenderer(booking.user)}</NavLink>

        switch (booking.book_type) {
            case BookingTypes.training.serializationKey:
                content = bookOwner;
                break;
            case BookingTypes.concert.serializationKey:
                content = "Concert";
                break;
            case BookingTypes.course.serializationKey:
                content = bookOwner;
                break;
            case BookingTypes.jam.serializationKey:
                content = bookOwner;
                break;
            case BookingTypes.other.serializationKey:
                content = bookOwner;
                break;
            case BookingTypes.record.serializationKey:
                content = bookOwner;
                break;
            default:
                console.log("Book type not recognized");
                content = "error";
                break;
        }
        return {
            type: booking.book_type,
            content: <span>{content}</span>,
            stringType: BookingTypes[booking.book_type].string,
        };
    }

    removeBooking(event) {
        event.preventDefault();
        this.props.removeBooking();
    }

    toggleModificationModal(event) {
        event.preventDefault();
        this.props.toggleModificationModal();
    }

    render() {

        const bookingOptions = this.props.booking ? this.renderBooking().content : (
            <button onClick={this.props.toggle} className="flat fat">Réserver</button>
        );

        const bookingSlotClass = this.renderBooking().type;

        const buttonDeleteRemoveOptions = this.props.isAllowedModification ? (
            <div className="button-wrapper">
                <button title="Supprimer la réservation" className="gray mr-2" onClick={this.removeBooking.bind(this)}>
                    <img alt="Supprimer la réservation" src={Delete}/></button>
                <button title="Modifier la réservation" className="gray" onClick={this.toggleModificationModal.bind(this)}><img alt="Modifier la réservation" src={Modify}/></button>
            </div>
        ) : ""

        const modifyBookingModalOptions = this.props.isAllowedModification ? (
            <ModifyBookingModal
                currentBookerInfo={this.props.currentBookerInfo}
                toggle={this.props.toggleModificationModal}
                modalVisible={this.props.modificationModalVisible}
                bookingSlot={this.props.bookingSlot}
                currentBookType={this.props.currentBookType}
                bookTypeOptions={this.props.bookTypeOptions}
                updateBooking={this.props.updateBooking}
                removeBooking={this.props.removeBooking}
                optionsBooker={this.props.options}/>) : "";

        return (
            <div className="carousel-slide">
                <div className="slide-content">
                    <div className={`slide-header  ${bookingSlotClass}`}>
                        <div className="top-row">
                            <div className="date">
                                {DateHelper.renderDate(this.props.bookingSlot.date).all}
                            </div>
                            {buttonDeleteRemoveOptions}
                        </div>
                        <div className="bottom-row">
                            <div className="hoursWrapper">
                                {this.props.bookingSlot.hourBookingSlot.hourBegin.toString()} - {this.props.bookingSlot.hourBookingSlot.hourEnd.toString()}
                            </div>
                            <div className="book-type-wrapper">
                                <div className={`booking-type`}>
                                    {this.renderBooking().stringType}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='booking-content'>
                        {bookingOptions}
                    </div>

                </div>
                {modifyBookingModalOptions}
                <Modal size="lg" modalTransition={{timeout: 50}} isOpen={this.props.modalVisible}
                       toggle={this.props.toggle}>
                    <ModalHeader toggle={this.props.toggle}>Réservation
                        créneau {DateHelper.renderDate(this.props.bookingSlot.date).all}, {this.props.bookingSlot.hourBookingSlot.hourBegin.toString()} - {this.props.bookingSlot.hourBookingSlot.hourEnd.toString()}</ModalHeader>
                    <ModalBody className="slide-modal-body">
                        <Row className="mb-5">
                            <Col className="label-container" xs="12" sm="12" md="12" lg="6" xl="5">
                                <label>Qu'est-ce que je réserve ?</label>
                            </Col>
                            <Col className="select-container" xs="12" sm="12" md="12" lg="6" xl="7">
                                <Select value={this.state.bookType} onChange={this.handleChangeBookType} lg={true}
                                        options={this.props.bookTypeOptions}/>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="label-container" xs="12" sm="12" md="12" lg="6" xl="5">
                                <label>Je réserve en tant que </label>
                            </Col>
                            <Col className="select-container" xs="12" sm="12" md="12" lg="6" xl="7">
                                <Select value={this.state.booker} onChange={this.handleChangeBooker} lg={true}
                                        options={this.props.options}/>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <button className="abord" onClick={this.props.toggle}>Annuler</button>
                        <button className="validate" onClick={this.validateBooking.bind(this)}>Valider</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Slide);