import React, {Component} from "react";

import axios from 'axios';

import './carousel.scss';
import {BookingSlot} from "../../../../utils/dateHelper";
import StringRenderer from "../../../../utils/stringRenderer";
import {connect} from 'react-redux';
import Slide from "./slide";
import Parser from "../../../../utils/parsers";
import {BookingSerializer} from "../../../../utils/serializers/booking";
import {BookingTypes} from "../../../apis/booking/booking";
import {BOOKING_APP_URL} from "../../../../../urls";

type Props = {
    booking: BookingSlot,
};

class SlideContainer extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            booking: null,
            waiting: true,
            modificationModalVisible: false,
        };
        this.getBooking(props.booking);
        this.toggle = this.toggle.bind(this);
        this.validateBooking = this.validateBooking.bind(this);
        this.toggleModificationModal = this.toggleModificationModal.bind(this);
        this.removeBooking = this.removeBooking.bind(this);
        this.updateBooking = this.updateBooking.bind(this);
    }

    getBooking(bookingSlot: BookingSlot) {
        axios.get(`${BOOKING_APP_URL}search/`, {
            params: {
                date_begin: Parser.serializeDate(bookingSlot.dateBegin)
            },
            headers: {
                Authorization: this.props.user.token
            }
        }).then(res => {
            this.setState({
                booking: res.data
            })
        }).catch(error => {
            if (error.response.status === 404) {
            }
        })
    }

    toggle() {
        this.setState({
            modalVisible: !this.state.modalVisible
        })
    }

    bookTypeOptions() {
        return Object.keys(BookingTypes).map(function (k) {
            let el = BookingTypes[k];
            el.id = el.serializationKey;
            return el
        });
    }

    options() {
        let bands = this.props.user.user.band_list;
        let renderedBand = bands.map(band => {
            return {
                type: "band",
                id: band.id,
                string: StringRenderer.bandRenderer(band)
            }
        });
        let user = {
            type: "user",
            id: -1,
            string: StringRenderer.userRenderer(this.props.user.user)
        };
        return [user, ...renderedBand]
    }

    validateBooking(bookerInfo, bookType) {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        let payload = BookingSerializer.serializeBook(false, bookType.string, bookType.string, bookType.serializationKey, this.props.booking, bookerInfo);

        axios.post(BOOKING_APP_URL, payload, header).then(res => {
            this.getBooking(this.props.booking);
            this.toggle();
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    isAllowedModification() {
        let booking = this.state.booking;
        if (!booking) return false;
        let isBooker = !!booking.user && this.props.user.user.id === booking.user.id;
        let isBandMember = !!booking.band && booking.band.members.find(member => {
            return member.id === this.props.user.user.id
        });
        return isBooker || isBandMember
    }

    /**
     * If a book exists, give the informations of the current booker
     * @returns {*}
     */
    currentBookerInfo() {
        if (!this.state.booking) return null;
        if (this.state.booking.band) {
            let band = this.state.booking.band;
            return {
                type: "band",
                id: band.id,
                string: StringRenderer.bandRenderer(band)
            }
        } else {
            return {
                type: "user",
                id: -1,
                string: StringRenderer.userRenderer(this.props.user.user)
            };
        }
    }

    /**
     * If a book exists, give the current booking type
     * @returns {*}
     */
    currentBookType() {
        if (!this.state.booking) return null;
        let res = BookingTypes[this.state.booking.book_type];
        res.id = this.state.booking.book_type;
        return res
    }

    toggleModificationModal() {
        this.setState({
            modificationModalVisible: !this.state.modificationModalVisible
        })
    }

    updateBooking(bookerInfo, bookType) {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        let payload = BookingSerializer.serializeBook(false, bookType.string, bookType.string, bookType.serializationKey, this.props.booking, bookerInfo);
        axios.put(`${BOOKING_APP_URL}${this.state.booking.id}/`, payload, header).then(res => {
            this.toggleModificationModal();
            this.setState({
                booking: res.data
            })
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    removeBooking() {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };

        axios.delete(`${BOOKING_APP_URL}${this.state.booking.id}/`, header).then(res => {
            this.setState({
                booking: null
            })
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    render() {
        return (
            <Slide modalVisible={this.state.modalVisible} toggle={this.toggle} bookingSlot={this.props.booking}
                   isAllowedModification={this.isAllowedModification()}
                   booking={this.state.booking}
                   options={this.options()} validateBooking={this.validateBooking}
                   currentBookerInfo={this.currentBookerInfo()}
                   toggleModificationModal={this.toggleModificationModal}
                   modificationModalVisible={this.state.modificationModalVisible}
                   updateBooking={this.updateBooking}
                   currentBookType={this.currentBookType()}
                   removeBooking={this.removeBooking}
                   bookTypeOptions={this.bookTypeOptions()}/>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(SlideContainer);