import React, {Component} from "react";

import '../../../../sass/main.scss'

class FormAddMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchUser: '',
        }
        this.handleChangeSearchUser = this.handleChangeSearchUser.bind(this)
    }

    handleChangeSearchUser(event) {
        this.setState({
            searchUser: event.target.value
        })
        this.props.changeUserQuery(event.target.value)
    }

    abordForm(event) {
        event.preventDefault();
        this.props.toggle()
    }

    isBandMember(memberId) {
        return this.props.bandMembers.find(member => {
            return memberId === member.id
        })
    }

    get foundProfiles() {
        let profiles = this.state.searchUser.trim().length ? this.props.foundUsers : this.props.users
        if (this.state.searchUser.trim().length && !this.props.foundUsers.length) {
            return <strong>Aucun utilisateur trouvé</strong>
        }

        return profiles.map((profile, index) => {
            let addOptions = this.isBandMember(profile.id) ? <span className="info">(déjà membre)</span> : ""
            return <li key={index} onClick={event => {
                    if (!this.isBandMember(profile.id)) this.toggleMember(event, profile.id)
                }
            }>
                <span>{profile.string}</span>{addOptions}
            </li>
        })
    }

    toggleMember(event, memberId) {
        event.preventDefault()
        this.props.toggleMember(memberId)
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="new-member-wrapper">
                    <div className="search-wrapper">
                        <div className="input-wrapper">
                            <input placeholder="Recherche un membre à ajouter" type="text" value={this.state.searchUser}
                                   onChange={this.handleChangeSearchUser}/>
                        </div>
                        <div>
                            <ul>
                                {this.foundProfiles}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-footer">
                    <button className="abord" onClick={this.abordForm.bind(this)}>Annuler</button>
                </div>
            </form>
        );
    }
}

export default FormAddMember;