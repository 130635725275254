import React, {Component} from "react";

import './home.scss'
import Time from  '../../../assets/images/time.svg'

class WaitingConfirmationPanel extends Component {

    render() {
        return (
            <div className="waiting-confirmation-panel">
                <h2>
                    Vérification du compte en cours
                </h2>
                <p>
                    Un membre du bureau va vérifier ton compte pour te donner l'accès à la plateforme. Si il traîne trop, contacte-le directement !
                </p>
                <img alt="Attente" src={Time}/>
            </div>
        );
    }
}

export default WaitingConfirmationPanel;