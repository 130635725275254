import React, { Component } from "react";

import './admin.scss'

class AdminGenericSection extends Component {

    render() {
        return (
            <div className="admin-generic-section">
                <h2 className="title">
                    {this.props.title}
                </h2>
                <div className="section-content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default AdminGenericSection;