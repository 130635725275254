import React, {Component} from "react";

import './userParams.scss'
import UserParamGenericSection from "./userParamGenericSection";
import ChangePassword from "./changePassword";

class AccountParams extends Component {

    render() {

        return (
            <UserParamGenericSection title="Paramètres de connexion">
                <ChangePassword/>
            </UserParamGenericSection>
        );
    }
}

export default AccountParams;