import React, {Component} from "react";
import axios from 'axios';

import './forgottenPassword.scss'
import { AUTH_URL } from "../../../urls";

class ResetPassword extends Component {
    constructor() {
        super();
        let params = new URLSearchParams(window.location.search);
        this.uid = params.get('uid');
        this.token = params.get('token');
        let invalidParams = !this.uid || !this.token;
        this.state = {
            invalidParams: invalidParams,
            password: "",
            passwordConfirm: "",
            waitResponse: false,
            resetSent: false,
            alreadySendTry: false,
            redirect: false,
        }
    }

    canSubmit() {
        return this.state.password === this.state.passwordConfirm
            && this.state.password.trim().length > 0
            && this.state.passwordConfirm.trim().length > 0
            && !this.state.waitResponse
    }

    handleChangePassword(event) {
        event.preventDefault();
        this.setState({
            password: event.target.value
        })
    }

    handleChangePasswordConfirm(event) {
        event.preventDefault();
        this.setState({
            passwordConfirm: event.target.value
        })
    }

    submitReset(event) {
        event.preventDefault();
        this.setState({
            alreadySendTry: true,
        });
        if (!this.canSubmit()) return -1;
        this.setState({
            waitResponse: true
        });
        axios.post(`${AUTH_URL}password/reset/confirm/`, {
            uid: this.uid,
            token: this.token,
            new_password1: this.state.password,
            new_password2: this.state.passwordConfirm,
        }).then(res => {
            this.setState({
                resetSent: true,
                waitResponse: false,
                redirect: true
            })

        }).catch(error => {
            console.log(error.response)
        })
    }

    optionsSpanConfirm() {
        if (!this.state.passwordConfirm.trim().length) return "";
        if (this.state.passwordConfirm === this.state.password) return "";
        if (!this.state.alreadySendTry) return "";
        return <span className="error">Les mots ne passes ne correspondent pas.</span>
    }

    render() {
        const options = this.state.redirect ?
            <span className="info valid">Ton mot de passe a bien été réinitialisé !</span> : (this.state.invalidParams ? (
            <span className="error">Une erreur s'est produite [Bad Params Reset Password]. Veuillez contacter l'équipe du Club'Zik.</span>
        ) : (
            <form>
                <div className="form-group">
                    <input type="password" placeholder="Quelle est ton nouveau mot de passe ?"
                           value={this.state.password} onChange={this.handleChangePassword.bind(this)}/>
                </div>

                <div className="form-group">
                    <input type="password" placeholder="Confirme ton nouveau mot de passe !"
                           value={this.state.passwordConfirm} onChange={this.handleChangePasswordConfirm.bind(this)}/>
                    {this.optionsSpanConfirm()}
                </div>
                <div className="submit-wrapper">
                    <button className={this.canSubmit() ? "" : "disabled"}
                            onClick={this.submitReset.bind(this)}>Envoyer
                    </button>
                </div>
            </form>
        ))

        return (
            <div className="forgotten-password">
                <h3>
                    Réinitialisation du mot de passe
                </h3>
                {options}
            </div>
        );
    }
}

export default ResetPassword;