import React, {Component} from "react";

import './userParams.scss'
import UserParamGenericAction from "./userParamGenericAction";
import axios from 'axios';
import {AUTH_URL} from "../../../../urls";
import connect from "react-redux/es/connect/connect";

class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            password: "",
            confirmPassword: "",
            oldPassword: "",
            alreadyTrySubmit: false,
            waitResponse: false,
            accepted: false,
            errorResponse: null,
        };
        this.handleChangeOldPassword = this.handleChangeOldPassword.bind(this)
        this.handleChangePassword = this.handleChangePassword.bind(this)
        this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this)
    }

    handleChangeOldPassword(event) {
        event.preventDefault()
        this.setState({
            oldPassword: event.target.value
        })
    }

    handleChangePassword(event) {
        event.preventDefault()
        this.setState({
            password: event.target.value
        })
    }

    handleChangeConfirmPassword(event) {
        event.preventDefault()
        this.setState({
            confirmPassword: event.target.value
        })
    }

    sendChangePassword(event) {
        event.preventDefault()
        this.setState({
            alreadyTrySubmit: true,
            waitResponse: true,
        })
        if (!this.canSubmit()) return -1
        let payload = {
            new_password1: this.state.password,
            new_password2: this.state.confirmPassword,
            old_password: this.state.oldPassword
        }

        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };

        axios.post(`${AUTH_URL}password/change/`, payload, header).then(res => {
            this.setState({
                waitResponse: false,
                accepted: true
            })
        }).catch(error => {
            let text = ""
            if (!!error.response.data.old_password) text = "ancien mot de passe invalide"
            else text = "erreur"
            this.setState({
                waitResponse: false,
                errorResponse: text
            })
            console.log(error.response)
        })
    }

    formErrorsOptions() {
        if (this.formCorrect() || !this.state.alreadyTrySubmit) return ""
        let message = "";
        if (this.state.password.trim().length < 8) message = "Le mot de passe doit contenir au moins 8 caractères.";
        if (this.state.password.trim().length === 0) message = "Tu n'as pas rentré de mot de passe.";
        if (this.state.oldPassword.trim().length === 0) message = "Tu n'as pas rentré ton ancien mot de passe.";
        if (this.state.password !== this.state.confirmPassword) message = "Les mots de passe ne sont pas identiques.";
        return <span className="error">{message}</span>
    }

    formCorrect() {
        return this.state.password.trim().length >= 8
            && this.state.password === this.state.confirmPassword
            && this.state.oldPassword.trim().length > 0
    }

    canSubmit() {
        return this.formCorrect() && !this.state.waitResponse
    }

    render() {
        const responseOptions = this.state.errorResponse ? <span className="error">{this.state.errorResponse}</span> : "";

        return (
            <UserParamGenericAction title="Changer de mot de passe">
                <form>
                    <div className="form-group">
                        <input type="password" placeholder="Entre ton ancien mot de passe" onChange={this.handleChangeOldPassword}
                               value={this.state.oldPassword}/>
                    </div>
                    <div className="form-group">
                        <input type="password" placeholder="Entre ton nouveau mot de passe" onChange={this.handleChangePassword}
                               value={this.state.password}/>
                        <span className="info">Le mot de passe doit contenir au moins 8 caractères.</span>
                    </div>
                    <div className="form-group">
                        <input type="password" placeholder="Confirme ton nouveau mot de passe"
                               onChange={this.handleChangeConfirmPassword} value={this.state.confirmPassword}/>
                    </div>
                    <div className="submit-wrapper">
                        {this.formErrorsOptions()}
                        {responseOptions}
                        {this.state.accepted ? <span className="info valid">Le mot de passe a été changé !</span> : <button className={this.canSubmit() ? "" : "disabled"} onClick={this.sendChangePassword.bind(this)}>Valider</button>}
                    </div>
                </form>
            </UserParamGenericAction>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(ChangePassword);