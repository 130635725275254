import React, {Component} from "react";

import {Route, Switch} from 'react-router';

import Booking from '../apis/booking/booking'
import Events from '../apis/events/events'
import Home from '../home/home'
import NotFound from '../notFound/notFound'
import UserParams from '../apis/userParams/userParams'

import './centralWrapper.scss'
import Profile from "../apis/profile/profileWrapperContainer";
import Bands from "../apis/bands/bands";
import Band from "../apis/bands/band";
import Admin from "../apis/admin/admin";
import ForgottenPassword from "../forgottenPassword/forgottenPassword";
import ResetPassword from "../forgottenPassword/resetPassword";

class CentralWrapper extends Component {

    render() {
        const options = this.props.user ? (
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/bands" component={Bands}/>
                <Route path="/band/:bandId" component={Band}/>
                <Route path="/admin-params" component={Admin}/>
                <Route path="/booking" component={Booking}/>
                <Route path="/profile/:profileId" component={Profile}/>
                <Route path="/events" component={Events}/>
                <Route path="/user-params" component={UserParams}/>
                <Route component={NotFound}/>
            </Switch>
        ) : (
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/bands" component={Home}/>
                <Route path="/admin-params" component={Home}/>
                <Route path="/booking" component={Home}/>
                <Route path="/profile" component={Home}/>
                <Route path="/events" component={Home}/>
                <Route path="/forgottenPassword/" component={ForgottenPassword}/>
                <Route path="/resetPassword" component={ResetPassword}/>
                <Route path="/user-params" component={Home}/>
                <Route component={NotFound}/>
            </Switch>
        )

        return (
            <div className={`centralWrapper ${this.props.screenManager.isSmall ? "limited" : ""}`}>
                {options}
            </div>
        );
    }
}

export default CentralWrapper;
