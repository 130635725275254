import {SET_SCREEN_SIZE} from '../actions'

const initialState = {
    isSmall: false,
    isMd: false,
    isNotXl: false
}

const isMobileDevice = () => {
    return navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/)
};

const screenManager = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCREEN_SIZE:
            return {
                isSmall: action.innerWidth <= 576 || isMobileDevice(),
                isMd: action.innerWidth > 576 && action.innerWidth < 1200,
                isNotXl: action.innerWidth < 1200
            };
        default:
            return state;
    }
};

export default screenManager
