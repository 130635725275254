import React, {Component} from "react";
import './mainContainer.scss'

import MainLoader from './mainLoader/mainLoader'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import axios from 'axios';

import { withCookies } from 'react-cookie';

import {connect} from 'react-redux'
import {setUser, setScreenSize} from '../../redux/actions'

import Header from '../header/header';
import CentralWrapper from '../centralWrapper/centralWrapper';
import SideBar from '../sidebar/sidebar';
import User from "../../classes/user";
import DRFAuthHelper from "../../utils/drfAuthHelper";
import {API_URL} from "../../../urls";

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waitingUserLog: true
        }
    }

    componentDidMount() {
        // Test if a token is present in cookie
        const { cookies } = this.props;
        const token = cookies.get('token');
        const timeTransition = 300;

        if (token) {
            axios.get(`${API_URL}rest-auth/user/`, {
                headers: {
                    Authorization: token
                }
            }).then(res => {
                // If the cookie is valid
                //  Set the user in cookie and redux state
                this.props.setUser(new User(DRFAuthHelper.getData(res), token));
                setTimeout(() => {
                    this.setState({
                        waitingUserLog: false
                    })
                }, timeTransition)
            }).catch(error => {
                // invalidate it in cookie storage
                cookies.remove('token', {path: "/"});
                setTimeout(() => {

                }, timeTransition)
            })
        } else {
            setTimeout(() => {
                this.setState({
                    waitingUserLog: false
                })
            }, timeTransition)
        }

        // Catch window size listener
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.props.setScreenSize(window.innerWidth);
    }

    renderContainer() {

        if (this.state.waitingUserLog) {
            return <MainLoader/>
        } else {
            return (
                <ReactCSSTransitionGroup
                    transitionName="fade"
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div key="1">
                        {!this.props.screenManager.isSmall ? <SideBar screenManager={this.props.screenManager} user={this.props.user}/> : ""}
                        <Header screenManager={this.props.screenManager}/>
                        <CentralWrapper user={this.props.user} screenManager={this.props.screenManager}/>
                    </div>
                </ReactCSSTransitionGroup>
            )
        }
    }

    render() {
        return (
            <div className="mainContainer">
                {this.renderContainer()}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    screenManager: state.screenManager
});

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    setScreenSize: innerWidth => dispatch(setScreenSize(innerWidth))
});

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
