export default class UserSerializer {

    static serializeCreateUser(username, isStaff, email, lastname, firstname, style_list = [], bandList = []) {
        let password = Array(8).fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz").map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
		 return {
            username: username,
            in_bureau: isStaff,
            email: email,
            name: lastname,
            password1: password,
            password2: password,
            firstname: firstname,
            styles: style_list,
            band_list: bandList,
        }
    }

    static updateUserFromAdmin(id, username, isStaff, email, lastname, firstname, verification, style_list = [], bandList = []) {
        return {
            id: id,
            username: username,
            in_bureau: isStaff,
            email: email,
            name: lastname,
            verified: verification,
            firstname: firstname,
            styles: style_list,
            band_list: bandList,
        }
    }

}
