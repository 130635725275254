import React, {Component} from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import '../../../../sass/main.scss'

import './profile.scss'

class FormGroupCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bandNameValid: null,
            bandName: '',
            bandDescription: '',
            bandDescriptionValid: null,
            searchUser: '',
        }

        this.handleChangeDescription = this.handleChangeDescription.bind(this)
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeSearchUser = this.handleChangeSearchUser.bind(this)

    }

    checkForm() {
        let bandNameValid = this.state.bandName.trim().length > 0
        this.setState({
            bandNameValid: bandNameValid
        })
        let formValid = bandNameValid
        if (formValid) {
            this.props.sendGroupCreationForm(this.state.bandName, this.state.bandDescription)
        }
    }

    handleChangeSearchUser(event) {
        this.setState({
            searchUser: event.target.value
        })
        this.props.changeUserQuery(event.target.value)
    }

    handleChangeDescription(event) {
        this.setState({
            bandDescription: event.target.value
        })
    }

    handleChangeName(event) {
        let newValue = event.target.value
        this.setState({
            bandName: newValue
        })
        if (this.state.bandNameValid !== null) {
            this.setState({
                bandNameValid: newValue.trim().length > 0
            })
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.checkForm();
    }

    abordForm(event) {
        event.preventDefault();
        this.props.toggle()
    }

    get classBandNameInput(): boolean {
        let validOption = this.state.bandNameValid === true ? "valid" : ""
        let invalidOption = this.state.bandNameValid === false ? "invalid" : ""
        return `${validOption} ${invalidOption}`
    }

    get foundProfiles() {
        let profiles = this.state.searchUser.trim().length ? this.props.foundUsers : this.props.users
        return profiles.map((profile, index) => {
            let addOptions = profile.selected ? <span className="info">(ajouté)</span> : ""
            return <li key={index} onClick={event => this.toggleMember(event, profile.id)}>
                <span>{profile.string}</span>{addOptions}
            </li>
        })
    }

    toggleMember(event, memberId) {
        event.preventDefault()
        this.props.toggleMember(memberId)
    }

    render() {
        const feedbackBandName = this.state.bandNameValid === false ?
            <span className="error">Il manque le nom du groupe</span> : ""

        const renderSelectedMembers = this.props.selectedMembers.map((member, index) => {
            return <div className="member-element" key={index}><span>{member.string}</span>
                <button onClick={event => this.toggleMember(event, member.id)}
                        className="no-style backgrounded-hover sm">x
                </button>
            </div>
        })

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <input placeholder="Nomme ton groupe" type="text" value={this.state.bandName}
                           onChange={this.handleChangeName}
                           className={this.classBandNameInput}/>
                    {feedbackBandName}
                </div>
                <div className="form-group">
                    <input placeholder={"Décris ton groupe ! (optionnel)"} type="text"
                           value={this.state.bandDescription} onChange={this.handleChangeDescription}/>
                </div>
                <div className="new-member-wrapper">
                    <div className="search-wrapper">
                        <div className="input-wrapper">
                            <input placeholder="Recherche un membre à ajouter" type="text" value={this.state.searchUser}
                                   onChange={this.handleChangeSearchUser}/>
                        </div>
                        <div>
                            <ul>
                                {this.foundProfiles}
                            </ul>
                        </div>
                    </div>
                    <div className="member-wrapper">
                        <div className="header-member">
                            <span className="info">Membres</span>
                        </div>
                        <div className="members">
                            <ul>
                                <ReactCSSTransitionGroup
                                    transitionName="lightTransX"
                                    transitionEnterTimeout={200}
                                    transitionLeaveTimeout={200}>
                                    {renderSelectedMembers}
                                </ReactCSSTransitionGroup>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="form-footer">
                    <button className="abord" onClick={this.abordForm.bind(this)}>Annuler</button>
                    <button className={`validate ${this.props.waitResponse ? "disabled" : ""}`}>Créer</button>
                </div>
            </form>
        );
    }
}

export default FormGroupCreation;