import React, {Component} from "react";
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import './booking.scss'
import StringRenderer from "../../../utils/stringRenderer";
import {BookingTypes} from "./booking";
import BookingModal from "./bookingModal";
import ModifyBookingModal from "./modifyBookingModal";

class BookingSlotPres extends Component {
    constructor(props) {
        super(props);
        this.handleClickSlot = this.handleClickSlot.bind(this)
    }

    renderBooking() {
        let booking = this.props.booking;
        let content;
        if (!booking) {
            return ""
        }

        let bookOwner = booking.band ? StringRenderer.bandRenderer(booking.band) : StringRenderer.userRenderer(booking.user);

        switch (booking.book_type) {
            case BookingTypes.training.serializationKey:
                content = `${bookOwner}`;
                break;
            case BookingTypes.concert.serializationKey:
                content = "Concert";
                break;
            case BookingTypes.course.serializationKey:
                content = `${bookOwner}`;
                break;
            case BookingTypes.jam.serializationKey:
                content = `${bookOwner}`;
                break;
            case BookingTypes.other.serializationKey:
                content = `${bookOwner}`;
                break;
            case BookingTypes.record.serializationKey:
                content = `${bookOwner}`;
                break;
            default:
                console.log("Book type not recognized");
                content = "error";
                break;
        }

        let classIsAllowedModification = this.props.isAllowedModification ? "allowedModif" : "";

        return (
            <div onClick={this.toggleModificationModal.bind(this)} className={`booking ${booking.book_type} ${classIsAllowedModification}`}>
                <div className="booking-type">
                    {BookingTypes[booking.book_type].string}
                </div>
                <div className="booking-content">
                    {content}
                </div>
            </div>
        )
    }

    toggleModificationModal(event) {
        event.preventDefault();
        this.props.toggleModificationModal()
    }

    handleClickSlot(event) {
        if (!this.props.booking) {
            this.props.toggleModal()
        }
    }

    render() {
        const emptyOptions = !this.props.booking ? "empty-slot" : "";
        const modalOptions = !this.props.booking && !this.props.loadingBooking ? (
            <BookingModal
                toggle={this.props.toggleModal}
                modalVisible={this.props.modalVisible}
                bookingSlot={this.props.bookingSlot}
                bookTypeOptions={this.props.bookTypeOptions}
                validateBooking={this.props.validateBooking}
                optionsBooker={this.props.optionsBooker}/>) : "";

        const modifyBookingModalOptions = this.props.isAllowedModification ? (
            <ModifyBookingModal
                currentBookerInfo={this.props.currentBookerInfo}
                toggle={this.props.toggleModificationModal}
                modalVisible={this.props.modificationModalVisible}
                bookingSlot={this.props.bookingSlot}
                currentBookType={this.props.currentBookType}
                bookTypeOptions={this.props.bookTypeOptions}
                updateBooking={this.props.updateBooking}
                removeBooking={this.props.removeBooking}
                optionsBooker={this.props.optionsBooker}/>) : "";

        return (
            <div onClick={this.handleClickSlot} className={`booking-slot ${emptyOptions}`}>
                <ReactCSSTransitionGroup
                    transitionName="TransFade"
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={100}>
                    {this.renderBooking()}
                </ReactCSSTransitionGroup>
                {modalOptions}
                {modifyBookingModalOptions}
            </div>
        );
    }
}

BookingSlotPres.propTypes = {
    currentBookType: PropTypes.object,
    booking: PropTypes.object,
    modalVisible: PropTypes.bool,
};

export default BookingSlotPres;