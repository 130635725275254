import React, {Component} from "react";

import {NavLink} from 'react-router-dom';

import './navbar.scss'
import UserImg from '../../../../assets/images/user.svg'
import BookingImg from '../../../../assets/images/reservation.svg'
import AdminImg from '../../../../assets/images/admin.svg'


class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            selected: null
        }
    }

    changeSelected(index) {
        this.setState({selected: index})
    }

    classDivLink(index) {
        return this.state.selected === index ? 'active' : 'not-active'
    }

    links() {
        const links = [
            {img: <img alt="Profil" src={UserImg}/>, name: "Profil", to: `profile/${this.props.user.user.id}/`},
            {img: <img alt="Réservation" src={BookingImg}/>, name: "Réservations", to: "booking"},
            // {name: "Évenements", to: "events"},
            // {name: "Cours", to: "courses"},
            // {name: "Groupes", to: "groups"},
            // {name: "Membres", to: "members"},
            // {name: "Partage Musical", to: "music_sharing"},
        ];

        if (this.props.user && (this.props.user.user.is_staff || this.props.user.user.in_bureau)) {
            links.push({img: <img alt="Administration" src={AdminImg}/>, name: "Administration", to: "admin-params"});
        }
        return links
    }

    renderLink(link, index) {
        return this.props.limited ? <NavLink key={index} onClick={() => this.changeSelected(index)} className="no-style"
                                             activeClassName="activeLink"
                                             to={`/${link.to}`}>{link.img}</NavLink> :
            <NavLink key={index} onClick={() => this.changeSelected(index)} className="no-style"
                     activeClassName="activeLink"
                     to={`/${link.to}`}>{link.img} {link.name}</NavLink>
    }

    render() {

        const renderedLinks = this.links().map((link, index) => {
            return (
                this.renderLink(link, index)
            )
        });

        return (
            <div className="navbar">
                {renderedLinks}
            </div>
        );
    }
}

export default Navbar;
