export default class DRFAuthHelper {
    static getToken (response) {
        return 'Token ' + response.data.key
    }

    static getData (response) {
        return response.data
    }

    static getName (response) {
        return DRFAuthHelper.getData(response).name
    }

    static getFirstname (response) {
        return DRFAuthHelper.getData(response).firstname
    }
}