import React, {Component} from "react";

import './admin.scss'
import Select from "../../generic/select/select";

class NewUserForm extends Component {
    constructor() {
        super();
        this.username = React.createRef();
        this.userFirstname = React.createRef();
        this.userLastname = React.createRef();
        this.userEmail = React.createRef();
    }

    handleChangeAdmin(option) {
        this.props.handleChangeAdmin(option)
    }

    render() {
        return (
            <form id="new-user-form">
                <div className="form-group">
                    <input
                        ref={this.username}
                        value={this.props.newUsername}
                        className={this.props.invalidFields.includes("username") ? "invalid" : ''}
                        type="text" onChange={this.props.handleChangeUserName}
                        placeholder="Nom d'utilisateur"/>
                </div>
                <div className="form-group">
                    <input
                        ref={this.userLastname}
                        value={this.props.newUserLastname}
                        className={this.props.invalidFields.includes("userLastname") ? "invalid" : ''}
                        type="text" onChange={this.props.handleChangeUserLastname} placeholder="Nom de famille"/>
                </div>
                <div className="form-group">
                    <input
                        ref={this.userFirstname}
                        value={this.props.newUserFirstname}
                        className={this.props.invalidFields.includes("userFirstname") ? "invalid" : ''}
                        onChange={this.props.handleChangeUserFirstname} type="text" placeholder="Prénom"/>
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        ref={this.userEmail}
                        value={this.props.newUserEmail}
                        onChange={this.props.handleChangeEmail}
                        className={this.props.invalidFields.includes("userEmail") ? "invalid" : ''}
                           placeholder="Adresse e-mail"/>
                </div>
                <div className="form-group">
                    <Select value={this.props.isAdminValue.id} onChange={this.handleChangeAdmin.bind(this)} lg={true}
                            options={this.props.optionsAdmin}/>
                </div>
                <div className="submit-wrapper">
                    <button className={this.props.canSubmit ? "" : "disabled"} onClick={this.props.addUser}>Ajouter</button>
                </div>
            </form>
        );
    }
}

export default NewUserForm;