var moment = require('moment');

export default class Parser {

    static parseDjangoDate (djangoDate): Date {
        return moment(djangoDate).toDate()
    }

    static serializeDate (date, toUtc: boolean) {
        return date.toJSON()
    }

    static parseLimitedDashDate(date: string): Date {
        var dateParts = date.split("-");
        return  new Date(dateParts[0], dateParts[1] - 1, dateParts[2]); // month is 0-based
    }
}
