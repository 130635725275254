import React, {Component} from "react";
import PropTypes from 'prop-types';

import './booking.scss'
import axios from "axios";
import {BOOKING_APP_URL} from "../../../../urls";
import Parser from "../../../utils/parsers";
import BookingSlotPres from "./bookingSlotPres";
import connect from "react-redux/es/connect/connect";
import StringRenderer from "../../../utils/stringRenderer";
import {BookingSerializer} from "../../../utils/serializers/booking";
import {BookingTypes} from "./booking";

class BookingSlotContainer extends Component {
    constructor(props) {
        super(props);
        this.mounted = true
        this.state = {
            booking: null,
            modalVisible: false,
            loadingBooking: true,
            modificationModalVisible: false,
        };
        this.getBooking();
        this.toggleModal = this.toggleModal.bind(this);
        this.validateBooking = this.validateBooking.bind(this);
        this.toggleModificationModal = this.toggleModificationModal.bind(this);
        this.removeBooking = this.removeBooking.bind(this);
        this.updateBooking = this.updateBooking.bind(this);
    }

    toggleModal() {
        if (this.bookAuthorized()) {
            this.setState({
                modalVisible: !this.state.modalVisible
            })
        }
    }

    optionsBooker() {
        let bands = this.props.user.user.band_list;
        let renderedBand = bands.map(band => {
            return {
                type: "band",
                id: band.id,
                string: StringRenderer.bandRenderer(band)
            }
        });
        let user = {
            type: "user",
            id: -1,
            string: StringRenderer.userRenderer(this.props.user.user)
        };
        return [user, ...renderedBand]
    }

    bookTypeOptions() {
        return Object.keys(BookingTypes).map(function (k) {
            let el = BookingTypes[k];
            el.id = el.serializationKey;
            return el
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(nextProp, nextState) {
        if (nextProp.bookingSlot.date - this.props.bookingSlot.date !== 0) {
            this.loadBooking()
        }
    }

    loadBooking() {
        let _this = this;
        this.setState({
            booking: null,
            loadingBooking: true,
        }, () => {
            _this.getBooking()
        });
    }

    // Catch the booking reservation if exists
    getBooking() {
        let beforeGetSlot = this.props.bookingSlot;
        axios.get(`${BOOKING_APP_URL}search/`, {
            params: {
                date_begin: Parser.serializeDate(beforeGetSlot.dateBegin)
            },
            headers: {
                Authorization: this.props.user.token
            }
        }).then(res => {
            if (this.mounted && this.props.bookingSlot === beforeGetSlot)
                this.setState({
                    booking: res.data,
                    loadingBooking: false,
                })
        }).catch(error => {
            if (this.mounted) {
                this.setState({
                    loadingBooking: false,
                });
            }
            if (error.response.status === 404) {
            }
        })
    }

    validateBooking(bookerInfo, bookType) {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        let payload = BookingSerializer.serializeBook(false, bookType.string, bookType.string, bookType.serializationKey, this.props.bookingSlot, bookerInfo);

        axios.post(BOOKING_APP_URL, payload, header).then(res => {
            this.getBooking();
            this.toggleModal();
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    bookAuthorized() {
        return this.props.bookingSlot.dateBegin.getDay() !== 0 
			&& this.props.bookingSlot.dateBegin - new Date() >= 0
    }

    isAllowedModification() {
        let booking = this.state.booking;
        if (!booking) return false;
        let isBooker = !!booking.user && this.props.user.user.id === booking.user.id;
        let isBandMember = !!booking.band && booking.band.members.find(member => {
            return member.id === this.props.user.user.id
        });
        let isInBureau = this.props.user.user.in_bureau;
        let isStaff = this.props.user.user.is_staff;
        return isStaff || isInBureau|| isBooker || isBandMember
    }

    /**
     * If a book exists, give the informations of the current booker
     * @returns {*}
     */
    currentBookerInfo() {
        if (!this.state.booking) return null;
        if (this.state.booking.band) {
            let band = this.state.booking.band;
            return {
                type: "band",
                id: band.id,
                string: StringRenderer.bandRenderer(band)
            }
        } else {
            return {
                type: "user",
                id: -1,
                string: StringRenderer.userRenderer(this.props.user.user)
            };
        }
    }

    /**
     * If a book exists, give the current booking type
     * @returns {*}
     */
    currentBookType() {
        if (!this.state.booking) return null;
        let res = BookingTypes[this.state.booking.book_type];
        res.id = this.state.booking.book_type;
        return res
    }

    toggleModificationModal() {
        this.setState({
            modificationModalVisible: !this.state.modificationModalVisible
        })
    }

    updateBooking(bookerInfo, bookType) {

        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        let payload = BookingSerializer.serializeBook(false, bookType.string, bookType.string, bookType.serializationKey, this.props.bookingSlot, bookerInfo);

        axios.put(`${BOOKING_APP_URL}${this.state.booking.id}/`, payload, header).then(res => {
            this.setState({
                booking: res.data,
                loadingBooking: false,
            })

            this.toggleModificationModal();
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    removeBooking() {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };

        axios.delete(`${BOOKING_APP_URL}${this.state.booking.id}/`, header).then(res => {
            this.toggleModificationModal();
            this.setState({
                booking: null
            })
        }).catch(error => {
            console.log(error.response.data)
        })
    }

    render() {
        return (
            <BookingSlotPres
                validateBooking={this.validateBooking}
                toggleModal={this.toggleModal}
                loadingBooking={this.state.loadingBooking}
                optionsBooker={this.optionsBooker()}
                modalVisible={this.state.modalVisible}
                bookingSlot={this.props.bookingSlot}
                currentBookerInfo={this.currentBookerInfo()}
                toggleModificationModal={this.toggleModificationModal}
                modificationModalVisible={this.state.modificationModalVisible}
                updateBooking={this.updateBooking}
                removeBooking={this.removeBooking}
                currentBookType={this.currentBookType()}
                isAllowedModification={this.isAllowedModification()}
                bookTypeOptions={this.bookTypeOptions()}
                booking={this.state.booking}/>
        );
    }
}

BookingSlotContainer.propTypes = {
    bookingSlot: PropTypes.object,
};

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(BookingSlotContainer);
