import React, { Component } from "react";

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import './mainLoader.scss';
var logo = require('../../../../assets/images/logo_club_limited.png')

class MainLoader extends Component {
    constructor() {
        super();
        this.state = {
            calc1Active: false,
            imgActive: false,
            timeouts: []
        }
    }

    componentDidMount() {
        this.runAnimation(this)
    }

    componentWillUnmount() {
        this.state.timeouts.map(timeout => {
            clearTimeout(timeout);
            return 0
        })
    }

    runAnimation(context) {
        context.setState({
            calc1Active: false,
            imgActive: false
        })

        var time1 = setTimeout(() => {
            context.setState({
                calc1Active: true
            })
        }, 30)
        var time2 = setTimeout(() => {
            context.setState({
                imgActive: true
            })
        }, 300)
        var time3 = setTimeout(context.runAnimation, 3000, context)
        var timeouts = context.state.timeouts
        context.setState({
            timeouts: [...timeouts, time1, time2, time3]
        })
    }

    render() {
        return (
            <ReactCSSTransitionGroup
                transitionName="fade"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeaveTimeout={500}
                transitionLeave={true}>
                <div className="MainLoader">
                    <div className='wrapper'>
                        <div className={this.state.calc1Active ? "active" : "inactive"}>
                            <div className={this.state.imgActive ? "active" : "inactive"}>
                                <img alt="Logo" src={logo} />
                            </div>
                        </div>
                    </div>
                </div>
            </ReactCSSTransitionGroup>

        );
    }
}

export default MainLoader;