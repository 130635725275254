import React from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import './admin.scss'
import Select from "../../generic/select/select";
import StringRenderer from "../../../utils/stringRenderer";
import {SelectedBooker} from "./bookingSection";

type Props = {
    setBooker: void,
    selectedBooker: SelectedBooker,
    handleChangeBookDay: void,
    bookDayValue: any,
    bookDayOptions: any[],
    handleChangeBookingSlot: void,
    bookingSlotOptions: any[],
    bookingSlotValue: any,
    dateBegin: Date,
    dateEnd: Date,
    dateBeginMin: Date,
    dateBeginMax: Date,
    dateEndMin: Date,
    dateEndMax: Date,
};

class RegularBookingForm extends React.Component<Props> {
    constructor() {
        super();
        this.state = {
            dropdownActive: false,
        }
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.bands = []
    }

    handleChangeBookType(newValue) {
        this.props.handleChangeBookType(newValue)
    }

    handleChangeBookerQuery(event) {
        event.preventDefault();
        this.props.handleChangeBookerQuery(event.target.value)
    }

    toggleDropdown() {
        this.setState({
            dropdownActive: !this.state.dropdownActive
        });
    }

    activateDropdown(event) {
        event.preventDefault();
        this.setState({
            dropdownActive: true
        })
    }

    selectBand(band) {
        this.props.setBooker(SelectedBooker.createSelectedBand(band))
    }

    selectMember(member) {
        this.props.setBooker(SelectedBooker.createSelectedMember(member))
    }

    renderFoundBands() {
        let header = this.props.foundBands.length ? <DropdownItem header>Groupes</DropdownItem> : "";
        let items = this.props.foundBands.length ? this.props.foundBands.map((band, index) => {
            return <DropdownItem
                key={index}
                onClick={() => this.selectBand(band)}>{StringRenderer.bandRenderer(band)}</DropdownItem>
        }) : "";
        return {
            header: header,
            items: items
        }
    }

    renderFoundMembers() {
        let header = this.props.foundMembers.length ? <DropdownItem header>Membres</DropdownItem> : "";
        let items = this.props.foundMembers.length ? this.props.foundMembers.map((member, index) => {
            return <DropdownItem
                key={index}
                onClick={() => this.selectMember(member)}>{StringRenderer.userRenderer(member)}</DropdownItem>
        }) : "";
        return {
            header: header,
            items: items
        }
    }

    handleChangeBookDay(bookDay) {
        this.props.handleChangeBookDay(bookDay)
    }

    handleChangeBookingSlot(hourBookingSlot) {
        this.props.handleChangeBookingSlot(hourBookingSlot)
    }

    handleChangeDateBegin(event) {
        event.preventDefault();
        this.props.handleChangeDateBegin(event.target.value)
    }

    handleChangeDateEnd(event) {
        event.preventDefault();
        this.props.handleChangeDateEnd(event.target.value)
    }

    render() {
        const dropdownOpen = this.state.dropdownActive && this.props.bookerQuery.trim().length > 0;

        const renderNoFound =
            !this.props.foundBands.length && !this.props.foundMembers.length ?
                <div className="no-found"><span>aucun résultat trouvé</span></div> : ""

        const optionsSelectedBooker = this.props.selectedBooker ? (
            this.props.selectedBooker.isBand() ? (
                <span className="booker">{StringRenderer.bandRenderer(this.props.selectedBooker.booker)} (Groupe)</span>
            ) : (
                <span className="booker">{StringRenderer.userRenderer(this.props.selectedBooker.booker)} (Membre)</span>
            )
        ) : <span className="info">aucun responsable pour le moment</span>;

        return (
            <form className="booking-form">
                <div className="form-group">
                    <Select
                        onChange={this.handleChangeBookType.bind(this)}
                        lg={true}
                        options={this.props.bookTypeOptions}
                        value={this.props.bookTypeValue}/>
                </div>
                <div className="form-group mt-3">
                    <label>Responsable du créneau</label>
                    <input
                        placeholder="Cherchez un utilisateur/groupe responsable du créneaux"
                        type="string"
                        onClick={this.activateDropdown.bind(this)}
                        value={this.props.bookerQuery}
                        onChange={this.handleChangeBookerQuery.bind(this)}/>
                    <Dropdown className="mb-2" isOpen={dropdownOpen} toggle={this.toggleDropdown}>
                        <DropdownToggle
                            tag="span"
                            data-toggle="dropdown"
                            aria-expanded={this.state.dropdownActive}>
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.renderFoundMembers().header}
                            {this.renderFoundMembers().items}
                            {this.renderFoundBands().header}
                            {this.renderFoundBands().items}
                            {renderNoFound}
                        </DropdownMenu>
                    </Dropdown>
                    <div className="selected-booker">
                        {optionsSelectedBooker}
                    </div>
                </div>
                <div className="form-group mt-3">
                    <Select
                        onChange={this.handleChangeBookDay.bind(this)}
                        lg={true}
                        options={this.props.bookDayOptions}
                        value={this.props.bookDayValue}/>
                </div>
                <div className="form-group mt-3">
                    <Select
                        onChange={this.handleChangeBookingSlot.bind(this)}
                        lg={true}
                        options={this.props.bookingSlotOptions}
                        value={this.props.bookingSlotValue}/>
                </div>
                <div className="form-group mt-3">
                    <label>Début période</label>
                    <input type="date" min={this.props.dateBeginMin} max={this.props.dateBeginMax}
                           onChange={this.handleChangeDateBegin.bind(this)}
                           value={this.props.dateBegin}/>
                </div>
                <div className="form-group mt-3">
                    <label>Fin période</label>
                    <input type="date" min={this.props.dateEndMin} max={this.props.dateEndMax}
                           onChange={this.handleChangeDateEnd.bind(this)}
                           value={this.props.dateEnd}/>
                </div>
                <div className="submit-wrapper">
                    <button className={this.props.canSubmit ? "" : "disabled"} onClick={this.props.sendRegularBooking}>Ajouter</button>
                </div>
            </form>
        );
    }
}

export default RegularBookingForm;