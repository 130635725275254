/**
 * Action types
 */
export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const ADD_BAND = 'ADD_BAND';

/**
 * Actions creator
 */
export function setUser(user) {
    return { type: SET_USER, user: user }
}

export function removeUser() {
    return { type: REMOVE_USER }
}

export function addBand(newBand) {
    return { type: ADD_BAND, newBand: newBand}
}