export const DatesBookingSlots = [1, 2, 3, 4, 5, 6];

export class Hour {
    hour: Number;
    minute: Number;
    second: Number;


    constructor(hour: Number, minute: Number = 0, second: Number = 0) {
        this.hour = hour;
        this.minute = minute;
        this.second = second;
    }

    toString(): string {
        let hour = this.hour < 10 ? '0' + this.hour : this.hour
        let minute = this.minute < 10 ? '0' + this.minute : this.minute
        return `${hour}:${minute}`
    }

    isAfter(date: Date,): boolean {
        if (this.hour > date.getHours()) {
            return true
        } else {
            if (date.getHours() === this.hour && this.minute > date.getMinutes()) {
                return true
            } else {
                if (date.getMinutes() === this.minute && this.second > date.getSeconds()) {
                    return true
                }
            }
        }
        return false
    }

    isBefore(date: Date) {
        return !this.isAfter(date)
    }
}

export class HourBookingSlot {

    hourBegin: Hour;
    hourEnd: Hour;

    constructor(hourBegin: Hour, hourEnd: Hour) {
        this.hourBegin = hourBegin;
        this.hourEnd = hourEnd;
    }

    renderHourBookingSlot() {
        let hourBegin = this.hourBegin;
        let hourHourBegin = hourBegin.hour < 9 ? `0${hourBegin.hour}` : hourBegin.hour;
        let minuteHourBegin = hourBegin.minute < 9 ? `0${hourBegin.minute}` : hourBegin.minute;


        let hourEnd = this.hourEnd;
        let hourHourEnd = hourEnd.hour < 9 ? `0${hourEnd.hour}` : hourEnd.hour;
        let minuteHourEnd = hourEnd.minute < 9 ? `0${hourEnd.minute}` : hourEnd.minute;

        let renderedBegin = `${hourHourBegin}:${minuteHourBegin}`;
        let renderedEnd = `${hourHourEnd}:${minuteHourEnd}`;

        return {

            begin: renderedBegin,
            end: renderedEnd,
            string: `De ${renderedBegin} à ${renderedEnd}`
        }
    }
}


export const hourBookingSlot0 = new HourBookingSlot(new Hour(8), new Hour(9));
export const hourBookingSlot1 = new HourBookingSlot(new Hour(9), new Hour(10));
export const hourBookingSlot2 = new HourBookingSlot(new Hour(10), new Hour(11));
export const hourBookingSlot3 = new HourBookingSlot(new Hour(11), new Hour(12));
export const hourBookingSlot4 = new HourBookingSlot(new Hour(12), new Hour(13));
export const hourBookingSlot5 = new HourBookingSlot(new Hour(13), new Hour(14));
export const hourBookingSlot6 = new HourBookingSlot(new Hour(14), new Hour(15));
export const hourBookingSlot7 = new HourBookingSlot(new Hour(15), new Hour(16));
export const hourBookingSlot8 = new HourBookingSlot(new Hour(16), new Hour(17));
export const hourBookingSlot9 = new HourBookingSlot(new Hour(17), new Hour(18));
export const hourBookingSlot10 = new HourBookingSlot(new Hour(18), new Hour(18,45));
export const hourBookingSlot11 = new HourBookingSlot(new Hour(18,45), new Hour(19,30));
export const hourBookingSlot12 = new HourBookingSlot(new Hour(19,30), new Hour(20,15));
export const hourBookingSlot13 = new HourBookingSlot(new Hour(20,15), new Hour(21));
export const hourBookingSlot14 = new HourBookingSlot(new Hour(21), new Hour(21,30));

export const HoursBookingSlots: HourBookingSlot[] = [
    hourBookingSlot0,
    hourBookingSlot1,
    hourBookingSlot2,
    hourBookingSlot3,
    hourBookingSlot4,
    hourBookingSlot5,
    hourBookingSlot6,
    hourBookingSlot7,
    hourBookingSlot8,
    hourBookingSlot9,
    hourBookingSlot10,
    hourBookingSlot11,
    hourBookingSlot12,
    hourBookingSlot13,
    hourBookingSlot14
]

export const dayEquivalences = [
    {
        full: 'Dimanche',
        limited: 'Dim'
    },
    {
        full: 'Lundi',
        limited: 'Lun'
    },
    {
        full: 'Mardi',
        limited: 'Mar'
    },
    {
        full: 'Mercredi',
        limited: 'Mer'
    },
    {
        full: 'Jeudi',
        limited: 'Jeu'
    },
    {
        full: 'Vendredi',
        limited: 'Ven'
    },
    {
        full: 'Samedi',
        limited: 'Sam'
    }
]

export const monthEquivalences = {
    0: 'Janvier',
    1: 'Février',
    2: 'Mars',
    3: 'Avril',
    4: 'Mai',
    5: 'Juin',
    6: 'Juillet',
    7: 'Août',
    8: 'Septembre',
    9: 'Octobre',
    10: 'Novembre',
    11: 'Décembre'
}

export class DateHelper {

    getDateInOneYear(date: Date) {
        date.setDate(date);
    }

    /**
     * Get the n available following booking slots
     */
    getFollowingDates(n: Number) {

    }

    static sameDayDates(date1: Date, date2: Date) {
        return date1.getDate() === date2.getDate()
            && date1.getMonth() === date2.getMonth()
            && date1.getFullYear() === date2.getFullYear()
    }

    static renderDate(date: Date) {
        let day: string = dayEquivalences[date.getDay()].full;
        let limitedDay: string = dayEquivalences[date.getDay()].limited;
        let dateNumber: string = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        let month: string = monthEquivalences[date.getMonth()];
        let monthNumber = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let year = date.getFullYear();

        return {
            day: day,
            limited: `${dateNumber}/${monthNumber}/${year}`,
            limitedDash: `${year}-${monthNumber}-${dateNumber}`,
            limitedDay: limitedDay,
            date: dateNumber,
            month: month,
            all: `${day} ${dateNumber} ${month}`
        }
    }

    /**
     * Get the nearest following booking slot
     * @param date
     */
    static getNearestBookingSlot(date: Date) {
        const hourBookingSlots = HoursBookingSlots;
        const nextDate: Date = new Date();
        var hourBookingSlotIndex;

        // Get the following booking slot hour
        if (hourBookingSlots[hourBookingSlots.length - 1].hourEnd.isBefore(nextDate)) {
            hourBookingSlotIndex = 0;
            nextDate.setDate(nextDate.getDate() + 1);
        } else {
            if (hourBookingSlots[0].hourBegin.isAfter(nextDate)) {
                hourBookingSlotIndex = 0
            } else {
                // find the containing booking slot index
                hourBookingSlotIndex = HoursBookingSlots.findIndex(hourBookingSlot => {
                    return hourBookingSlot.hourBegin.isBefore(nextDate) && hourBookingSlot.hourEnd.isAfter(nextDate);
                })
            }
        }

        // Get the following booking slot day
        if (nextDate.getDay() === 0) {
            nextDate.setDate(nextDate.getDate() + 1);
        }
        return new BookingSlot(nextDate, hourBookingSlotIndex)
    }

    static decrementDate(date: Date) {
        date.setDate(date.getDate() - 1)
    }

    static addDays(date: Date, n: number) {
        date.setDate(date.getDate() + n);
        return date
    }

    static copyAddDays(date: Date, n: number) {
        let newDate = new Date(date);
        newDate.setDate(date.getDate() + n);
        return newDate
    }

    static datesEqual(date1: Date, date2: Date): boolean {
        return date1.getTime() === date2.getTime()
    }

    /**
     * Get the nearest previous day in dayIndex of a given date
     * @param date
     * @param dayIndex: index of day to get (Sunday : 0, Saturday : 6)
     */
    static getNearestPreviousDayDate(date: Date, dayIndex: number): Date {
        let res = new Date(date)
        while (res.getDay() !== dayIndex) {
            DateHelper.decrementDate(res)
        }
        return res
    }

    /**
     * Get the nearest next day in dayIndex of a given date
     * @param date
     * @param dayIndex: index of day to get (Sunday : 0, Saturday : 6)
     */
    static getNearestNextDayDate(date: Date, dayIndex: number) {
        let res = new Date(date);
        while (res.getDay() !== dayIndex) {
            DateHelper.addDays(res, 1)
        }
        return res
    }

    static getDayEquivalence(date: Date) {
        return dayEquivalences[date.getDay()]
    }

    static renderHour(date: Date) {
        let hour = date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours();
        let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        return `${hour}:${minutes}`
    }

}

export class BookingSlot {
    date: Date;
    hourBookingSlotIndex: number;

    constructor(date: Date = new Date(), hourBookingSlotIndex: number = 0) {
        this.date = date;
        this.hourBookingSlotIndex = hourBookingSlotIndex;
    }

    get hourBookingSlot(): HourBookingSlot {
        return HoursBookingSlots[this.hourBookingSlotIndex]
    }

    get dateBegin(): Date {
        let hourBegin = this.hourBookingSlot.hourBegin;
        let res = new Date(this.date);
        res.setUTCDate(this.date.getDate())
        res.setUTCMonth(this.date.getMonth())
        res.setUTCHours(hourBegin.hour, hourBegin.minute, hourBegin.second, 0);
        return res
    }

    getNextBookingSlot(): BookingSlot {
        let res = new BookingSlot(new Date(this.date.getTime()), this.hourBookingSlotIndex);
        if (res.date.getDay() !== 0) {
            if (res.hourBookingSlotIndex !== HoursBookingSlots.length - 1) {
                res.hourBookingSlotIndex += 1
            } else {
                res.hourBookingSlotIndex = 1;
                res.date.setDate(res.date.getDate() + 1)
            }
        } else {
            res.hourBookingSlotIndex = 1;
            res.date.setDate(res.date.getDate() + 1)
        }
        return res
    }

}
