import React, {Component} from "react";

import './admin.scss'
import Delete from "../../../../assets/images/delete.svg";
import Modify from "../../../../assets/images/modify.svg";
import Select from "../../generic/select/select";
import axios from "axios";
import { USERS_URL, RESPOND_USER_VERIF } from "../../../../urls";
import UserSerializer from "../../../utils/serializers/user";
import Spinner1 from "../../generic/spinner/spinner1";
import {DateHelper} from '../../../utils/dateHelper'
import Parser from '../../../utils/parsers'
import {NavLink} from 'react-router-dom';

class Member extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newLastname: this.props.member.name,
            newUsername: this.props.member.username,
            newFirstname: this.props.member.firstname,
            newEmail: this.props.member.email,
            verified: this.props.member.verified,
            modifMember: false,
            userStatus: this.props.member.in_bureau ? this.optionsUserStatus()[1] : this.optionsUserStatus()[0],
            waitChangeUser: false,
        }
        this.handleVerifyMember = this.handleVerifyMember.bind(this)
        this.toggleModifMember = this.toggleModifMember.bind(this);
        this.handleChangeLastname = this.handleChangeLastname.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangeFirstname = this.handleChangeFirstname.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.deleteMember = this.deleteMember.bind(this)
    }

    deleteMember(event) {
        event.preventDefault()
        let verif = window.confirm("/!\\ Êtes-vous sûr de supprimer l'utilisateur ? L'action est irréversible.");
        if (verif) this.props.deleteMember(this.props.member.id)
    }

    toggleModifMember(event) {
        if (event) event.preventDefault();
        this.setState({
            modifMember: !this.state.modifMember,
            newLastname: this.props.member.name,
            newUsername: this.props.member.username,
            newFirstname: this.props.member.firstname,
            newEmail: this.props.member.email,
            newVerification: this.props.member.verified,
            userStatus: this.props.member.in_bureau ? this.optionsUserStatus()[1] : this.optionsUserStatus()[0],
        })
    }

    optionsUserStatus() {
        return [
            {
                id: false,
                string: "utilisateur",
            },
            {
                id: true,
                string: "admin",
            }

        ]
    }

    handleChangeLastname(event) {
        event.preventDefault();
        this.setState({
            newLastname: event.target.value
        })
    }

    handleChangeUsername(event) {
        event.preventDefault();
        this.setState({
            newUsername: event.target.value
        })
    }

    handleChangeFirstname(event) {
        event.preventDefault();
        this.setState({
            newFirstname: event.target.value
        })
    }

    handleChangeEmail(event) {
        event.preventDefault();
        this.setState({
            newEmail: event.target.value
        })
    }

    renderStatus() {
        return this.props.member.in_bureau || this.props.member.is_staff ? "Admin" : "Utilisateur"
    }

    handleChangeUserStatus(newStatus) {
        this.setState({
            userStatus: newStatus
        })
    }

    handleVerifyMember(event) {
        event.preventDefault()
        this.setState({
            newVerification: true
        }, () => {
            let header = {
                headers: {
                    "Content-Type": 'application/json',
                    Authorization: this.props.user.token
                }
            };
            let user = this.props.member;
            this.setState({
                waitChangeUser: true
            });

            axios.post(`${RESPOND_USER_VERIF+user.id}/`, 
            { verified: true }, header).then((res) => {
                this.setState({
                    waitChangeUser: false
                })
                this.props.updateMember(res.data);
            }).catch(error => {
                this.setState({
                    waitChangeUser: false
                });
                console.log(error);
            })
        })
    }

    sendModifMember(event) {
        event.preventDefault();
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        let user = this.props.member;
        let payload = UserSerializer.updateUserFromAdmin(user.id, this.state.newUsername, this.state.userStatus.id, this.state.newEmail, this.state.newLastname, this.state.newFirstname, this.state.newVerification, user.style_list, user.band_list);
        this.setState({
            waitChangeUser: true
        });
        axios.put(`${USERS_URL}${user.id}/`, payload, header).then(res => {
            this.setState({
                waitChangeUser: false
            })
            this.props.updateMember(res.data);
            this.toggleModifMember()
        }).catch(error => {
            this.setState({
                waitChangeUser: false
            });
            console.log(error.response);
        })
    }

    render() {
        const actionsOptions = this.state.waitChangeUser ? (
                <Spinner1/>
        ) : (
            <div>
                <button className="abord mr-5" title="annuler" onClick={this.toggleModifMember.bind(this)}>annuler
                </button>
                <button title="valider" className="validate" onClick={this.sendModifMember.bind(this)}>ok</button>
            </div>
        )

        return (
            <tr className="member">
                <td>
                    {this.state.modifMember ? <input value={this.state.newUsername} onChange={this.handleChangeUsername}/> : <NavLink to={`/profile/${this.props.member.id}/`}>{this.props.member.username}</NavLink>}
                </td>
                <td>
                    {this.props.member.last_visit ? DateHelper.renderDate(Parser.parseDjangoDate(this.props.member.last_visit)).limited : "-"}
                </td>
                <td>
                    {this.state.modifMember ? <input value={this.state.newLastname} onChange={this.handleChangeLastname}/> : this.props.member.name}
                </td>
                <td>
                    {this.state.modifMember ? <input value={this.state.newFirstname} onChange={this.handleChangeFirstname}/> : this.props.member.firstname}
                </td>
                <td>
                    {this.state.modifMember ? <input value={this.state.newEmail} onChange={this.handleChangeEmail}/> : this.props.member.email}
                </td>
                <td>
                    {this.props.member.verified ? "oui" : "non"}
                </td>
                <td>
                    {this.state.modifMember ? <Select value={this.state.userStatus} onChange={this.handleChangeUserStatus.bind(this)} lg={true}
                            options={this.optionsUserStatus()}/> : this.renderStatus()}
                </td>
                <td>
                    {this.state.modifMember ? actionsOptions : 
                        (<div>
                            <button className="gray mr-1" title="Supprimer le membre" onClick={this.deleteMember}><img alt="Supprimer le membre" src={Delete}/></button>
                            <button title="Paramétrer l'utilisateur" className="gray mr-1" onClick={this.toggleModifMember.bind(this)}><img alt="Paramétrer l'utilisateur" src={Modify}/></button>
                            {this.props.member.verified ? "" : (this.state.waitChangeUser ? <Spinner1/> : <button onClick={this.handleVerifyMember} className="gray" title="Vérifier l'utilisateur">Vérif</button>)}
                        </div>)}
                </td>
            </tr>
        );
    }
}

export default Member;