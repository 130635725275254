import React, {Component} from "react";

import './admin.scss'
import UserSection from "./userSection";
import BookingSection from "./bookingSection";
import {Redirect} from "react-router";
import connect from "react-redux/es/connect/connect";

class Admin extends Component {

    render() {
        const redirectOptions = (this.props.user.user.is_staff || this.props.user.user.in_bureau) ?
            <div className="admin">
                <BookingSection/>
                <UserSection/>
            </div> : <Redirect to="/"/>;

        return (
            <div>
                {redirectOptions}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Admin);