import React, {Component} from "react";

import PropTypes from 'prop-types';

import './carousel.scss';
import LeftArrow from '../../../../../assets/images/navigation/left_arrow.svg';
import RightArrow from '../../../../../assets/images/navigation/right_arrow.svg';
import SlideContainer from "./slideContainer";

const gapX = 300;
const margin_item = 0.75;

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offsetX: 0,
            incrementOffset: 0,
        }
        this.mediaMatch = window.matchMedia('(min-width: 576px)');
    }

    handleNext() {
        this.setState({
            offsetX: this.state.offsetX - gapX,
            incrementOffset: this.state.offsetX === 0 ? this.state.incrementOffset - 1 : this.state.incrementOffset - 2,
        })
    }

    handlePrev() {
        this.setState({
            offsetX: this.state.offsetX + gapX,
            incrementOffset: this.state.offsetX === 0 ? this.state.incrementOffset + 1 : this.state.incrementOffset + 2,
        })
    }

    nbSlides() {
        return this.props.bookings.length
    }

    render() {

        const divStyle = {
            transform: this.mediaMatch.matches && `translate(calc(${this.state.offsetX}px + ${this.state.incrementOffset * margin_item}rem))`
        };

        const buttonPrevOptions = !this.state.offsetX ? <span></span> : (
            <button className="left no-style backgrounded-hover" onClick={() => this.handlePrev()}>
                <img alt="Créneaux précédents" src={LeftArrow}/>
            </button>)

        const buttonNextOptions = -this.state.incrementOffset > 2*(this.nbSlides() - 2) ? <span></span> : (
            <button className="right no-style backgrounded-hover" onClick={() => this.handleNext()}>
                <img alt="Créneaux suivants" src={RightArrow}/>
            </button>)

        const bookingSlots = this.props.bookings.map((booking, index) => {
            return <SlideContainer key={index} booking={booking}/>
        })

        return (
            <div className="carousel">
                {this.mediaMatch.matches && <div className="carousel-header" >
                    <div className="buttonWrapper">
                        {buttonPrevOptions}
                        {buttonNextOptions}
                    </div>
                </div>}
                <div className="carousel-inner-container">
                    <div className="carousel-inner" style={divStyle}>
                        {bookingSlots}
                    </div>
                </div>
            </div>
        );
    }
}

Carousel.propTypes = {
    validateBooking: PropTypes.func
};

export default Carousel;
