import React, {Component} from "react";

import './booking.scss'
import { DateHelper, HoursBookingSlots, monthEquivalences } from "../../../utils/dateHelper";
import DayCol from "./dayCol";
import Left_arrow from "../../../../assets/images/navigation/left_arrow.svg";
import Right_arrow from "../../../../assets/images/navigation/right_arrow.svg";

export const BookingTypes = {
    concert: {
        string: "Concert",
        serializationKey: "concert"
    },
    jam: {
        string: "Jam",
        serializationKey: "jam"
    },
    course: {
        string: "Cours",
        serializationKey: "course"
    },
    training: {
        string: "Répétition",
        serializationKey: "training"
    },
    record: {
        string: "Enregistrement",
        serializationKey: "record"
    },
    other: {
        string: "Autre",
        serializationKey: "other"
    }
};

class Booking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datePanel: new Date(),
        }
    }

    renderDatesCurrentPanel() {
        let currentDate = DateHelper.getNearestPreviousDayDate(this.state.datePanel, 1);
        let datesColumns = [];
        let colIndex = 0;
        while (!DateHelper.datesEqual(currentDate, DateHelper.getNearestNextDayDate(this.state.datePanel, 0))) {
            let loopDate = new Date(currentDate)
            datesColumns.push(<DayCol key={colIndex} currentDate={loopDate}/>);
            colIndex++;
            DateHelper.addDays(currentDate, 1);
        }
        colIndex++;
        datesColumns.push(<DayCol key={colIndex} currentDate={currentDate}/>);
        return datesColumns
    }

    renderHours() {
        return HoursBookingSlots.map((hourBookingSlot, index) => {
            return (
                <div key={index} className="booking-slot-hours">
                    {index === 0 ? <div className="begin">
                        {hourBookingSlot.hourBegin.toString()}
                    </div> : <span></span>}
                    <div className="end">
                        {hourBookingSlot.hourEnd.toString()}
                    </div>
                </div>
            )
        })
    }

    handlePrev() {
        let newDate = new Date(this.state.datePanel.setDate(this.state.datePanel.getDate() - 7))
        this.setState({
            datePanel: newDate
        })
    }

    handleNext() {
        let newDate = new Date(this.state.datePanel.setDate(this.state.datePanel.getDate() + 7))
        this.setState({
            datePanel: newDate
        })
    }

    renderWeek() {
        let nearestMonday = DateHelper.getNearestPreviousDayDate(this.state.datePanel, 1);
        let nearestSunday = DateHelper.getNearestNextDayDate(this.state.datePanel, 0);
        let secondYearOption = nearestMonday.getFullYear() !== nearestSunday.getFullYear() ? nearestMonday.getFullYear() : ""
        return `${nearestMonday.getDate()} ${monthEquivalences[nearestMonday.getMonth()]} ${secondYearOption} - ${nearestSunday.getDate()} ${monthEquivalences[nearestSunday.getMonth()]}  ${nearestSunday.getFullYear()}`
    }

    render() {
        return (
            <div className="booking-section">
                <div className="weekSelector">
                    <div className="arrow-wrapper">
                        <button className="left no-style" onClick={() => this.handlePrev()}>
                            <img alt="Créneaux précédents" src={Left_arrow}/>
                        </button>
                    </div>
                    <div>
                        {this.renderWeek()}
                    </div>
                    <div className="arrow-wrapper">
                        <button className="right no-style" onClick={() => this.handleNext()}>
                            <img alt="Créneaux suivants" src={Right_arrow}/>
                        </button>
                    </div>
                </div>
                <div className="booking-section-wrapper">
                    <div className="side-col">
                        <div className="header-side-col">

                        </div>
                        <div className="content-side-col">
                            {this.renderHours()}
                        </div>
                    </div>
                    <div className="bookings-wrapper">
                        {this.renderDatesCurrentPanel()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Booking;