import React, { Component } from "react";

import { NavLink } from 'react-router-dom';

import Navbar from './menu/navbar'

import './sidebar.scss'
import '../../../sass/main.scss'

import Logo_ltd_sm from '../../../assets/images/logo_club_limited_white.svg'
import LogoLimited2 from '../../../assets/images/logo_club_ltd_2_white.svg'
import ExitIcon from '../../../assets/images/x-button.svg' 

class SideBar extends Component {

    constructor(props) {
        super(props);
        if (this.props.screenManager.isSmall) {
            this.desactivateMenu = this.props.desactivateMenu.bind(this);
        }
    }

    render() {
        const isLimitedBar = this.props.screenManager.isMd
        const isMobile = this.props.screenManager.isSmall

        const logo_options = isLimitedBar ? <img alt="Logo" src={LogoLimited2}/> : isMobile ? "" :  <img alt="Logo" src={Logo_ltd_sm}/>;
        const logoWrapper = <div className="logo_wrapper">
            <NavLink className="no-style" to="/">
                {logo_options}
            </NavLink>
        </div>

        const renderNavbar = this.props.user ?(this.props.user.user.verified ? <Navbar
            limited={isLimitedBar}
            mobile={isMobile}
            user={this.props.user}/> : "") : ""

        return (
            <div className={`sidebar ${isLimitedBar ? "limited" : ""} ${isMobile ? "mobile" : ""} ${this.props.active ? "active" : ""}`}>
                {isMobile ? <button onClick={this.desactivateMenu} id="exit-menu-button" className="no-style">
                    <img alt="exit-menu-button" src={ExitIcon}/>
                </button> : ""}
                {!isMobile ? logoWrapper : ""}
                {renderNavbar}
            </div>
        );
    }
}

export default SideBar;