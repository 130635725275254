export class BandSerializer {
    static serializeNewBand(name: string, description: string, members: number[]= [], styles = []) {
        return {
            name: name,
            description: description,
            members: members.map(member => {
                return member.id
            }),
            styles: styles
        }
    }
}