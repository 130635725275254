import React, {Component} from "react";

import {connect} from 'react-redux';
import axios from 'axios';

import './bands.scss'
import {Col, Row, Container} from "reactstrap";
import {BAND_APP_URL} from "../../../../urls";

const bandImg = "http://www.beachweathermusic.com/wp-content/uploads/2017/12/Rock.jpg"

class Bands extends Component {
    constructor() {
        super();
        this.state = {
            bands: []
        }
    }

    componentDidMount() {
        axios.get(BAND_APP_URL, {
            headers: {
                Authorization: this.props.user.token
            }
        }).then(res => {
            this.setState({
                bands: res.data
            })
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        const renderBands = this.state.bands.map(band => {
            return (
                <Col className="band-item" key={band.id} xs="4" sm="4" md="3" lg="2" xl="2">
                    <div className="band-image">
                        <img alt={`${band.name}`} src={bandImg}/>
                    </div>
                    {band.name}
                </Col>
            )
        })


        return (
            <Container fluid={true} className="bands">
                <Row>
                    {renderBands}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Bands);