import React, {Component} from "react";

import './booking.scss'
import Select from "../../generic/select/select";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {DateHelper} from "../../../utils/dateHelper";
import Delete from '../../../../assets/images/delete.svg'

class ModifyBookingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            booker: props.currentBookerInfo,
            bookType: props.currentBookType
        };
        this.handleChangeBookType = this.handleChangeBookType.bind(this);
        this.handleChangeBooker = this.handleChangeBooker.bind(this);
        this.updateBooking = this.updateBooking.bind(this);
        this.removeBooking = this.removeBooking.bind(this)
    }

    handleChangeBooker(value) {
        this.setState({booker: value});
    }

    handleChangeBookType(value) {
        this.setState({bookType: value});
    }

    updateBooking(event) {
        event.preventDefault();
        this.props.updateBooking(this.state.booker, this.state.bookType);
    }

    removeBooking(event) {
        event.preventDefault();
        this.props.removeBooking();
    }

    render() {
        return (
            <Modal size="lg" modalTransition={{timeout: 50}} isOpen={this.props.modalVisible}
                   toggle={this.props.toggle}>
                <ModalHeader toggle={this.toggle}>Modifier réservation créneau
                    - {DateHelper.renderDate(this.props.bookingSlot.date).all}, {this.props.bookingSlot.hourBookingSlot.hourBegin.toString()} - {this.props.bookingSlot.hourBookingSlot.hourEnd.toString()}</ModalHeader>
                <ModalBody className="slide-modal-body">
                    <Row className="mb-5">
                        <Col className="label-container" xs="12" sm="12" md="12" lg="6" xl="5">
                            <label>Qu'est-ce que je réserve ?</label>
                        </Col>
                        <Col className="select-container" xs="12" sm="12" md="12" lg="6" xl="7">
                            <Select value={this.state.bookType} onChange={this.handleChangeBookType} lg={true}
                                    options={this.props.bookTypeOptions}/>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col className="label-container" xs="12" sm="12" md="12" lg="6" xl="5">
                            <label>Je réserve en tant que </label>
                        </Col>
                        <Col className="select-container" xs="12" sm="12" md="12" lg="6" xl="7">
                            <Select value={this.state.booker} onChange={this.handleChangeBooker} lg={true}
                                    options={this.props.optionsBooker}/>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button className="gray mr-5" title="Supprimer la réservation" onClick={this.props.removeBooking}><img alt="Supprimer la réservation" src={Delete}/></button>
                    <button className="abord" onClick={this.props.toggle}>Annuler</button>
                    <button className="validate" onClick={this.updateBooking}>Valider</button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModifyBookingModal;