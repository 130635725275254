import React, { Component } from "react";

import './admin.scss'

class AdminGenericAction extends Component {

    render() {
        return (
            <div className="admin-generic-action">
                <h3 className="title">
                    {this.props.title}
                </h3>
                <div className="content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default AdminGenericAction;