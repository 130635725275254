import React, {Component} from "react";
import './select.scss';

class Select extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        event.preventDefault();
        // find the corresponding option
        let correspondingOption = this.props.options.find(option => {
            return option.id.toString() === event.target.value
        })
        this.props.onChange(correspondingOption)
    }

    render() {
        const options = this.props.options.map((option, index) => {
            return <option value={option.id} key={index}>{option.string}</option>
        })
        return (
            <span className="custom-dropdown custom-dropdown--large custom-dropdown--white">
                <select value={this.props.value.id} onChange={this.handleChange}
                        className="custom-dropdown__select custom-dropdown__select--white">
                    {options}
                </select>
            </span>
        );
    }
}

export default Select;