import {BookingSlot, HourBookingSlot} from "../dateHelper";
import Parser from "../parsers";

export class BookingSerializer {
    static serializeBook(regular: boolean, name: string, description: string, type: string, bookingSlot: BookingSlot, bookerInfo) {
        // set correct hours on booking slots
        let dateBegin = new Date(bookingSlot.date)
        let hourBegin = bookingSlot.hourBookingSlot.hourBegin
        let dateEnd = new Date(bookingSlot.date)
        let hourEnd = bookingSlot.hourBookingSlot.hourEnd

        dateBegin.setUTCDate(dateBegin.getDate())
        dateBegin.setUTCHours(hourBegin.hour, hourBegin.minute, 0, 0);
        dateEnd.setUTCDate(dateEnd.getDate())
        dateEnd.setUTCHours(hourEnd.hour, hourEnd.minute, 0, 0);

        let bandId;

        if (bookerInfo.type === "band") {
            bandId = bookerInfo.id;
        }

        return {
            name: name,
            regular: regular,
            description: description,
            band: bandId,
            book_type: type,
            date_begin: Parser.serializeDate(dateBegin),
            date_end: Parser.serializeDate(dateEnd)
        }
    }

    static serializeRegularBookingSlot(book_type, booker: SelectedBooker, dateBegin, dateEnd, bookingSlot: HourBookingSlot) {


        let hourBegin = bookingSlot.hourBegin;
        let hourEnd = bookingSlot.hourEnd;

        dateBegin.setUTCDate(dateBegin.getDate())
        dateBegin.setUTCHours(hourBegin.hour, hourBegin.minute, 0, 0);
        dateEnd.setUTCDate(dateEnd.getDate())
        dateEnd.setUTCHours(hourEnd.hour, hourEnd.minute, 0, 0);

        let res = {
            name: book_type,
            description: book_type,
            book_type: book_type,
            date_begin: dateBegin,
            date_end: dateEnd,
        };

        if (booker.isBand()) {
            res.band = booker.getId();
            res.user = null;
        } else {
            res.user = booker.getId();
            res.band = null;
        }

        return res;
    }

    static serializePutRegularBookingSlot(book_type, dateBegin, dateEnd, bookingSlot) {
        let hourBegin = bookingSlot.hourBegin;
        let hourEnd = bookingSlot.hourEnd;

        dateBegin.setUTCDate(dateBegin.getDate())
        dateBegin.setUTCHours(hourBegin.hour, hourBegin.minute, 0, 0);
        dateEnd.setUTCDate(dateEnd.getDate())
        dateEnd.setUTCHours(hourEnd.hour, hourEnd.minute, 0, 0);

        return {
            name: book_type,
            description: book_type,
            book_type: book_type,
            date_begin: dateBegin,
            date_end: dateEnd,
        };
    }


}
