import React, {Component} from "react";

import axios from 'axios';

import {connect} from 'react-redux';

import ProfileWrapper from './profileWrapper';
import './profile.scss';
import StringRenderer from "../../../utils/stringRenderer";
import {BandSerializer} from "../../../utils/serializers/bands";
import {API_URL} from "../../../../urls";
import {addBand, setUser} from "../../../redux/actions";
import Spinner2 from '../../generic/spinner/spinner2';

class ProfileWrapperContainer extends Component {
    constructor() {
        super();
        this.state = {
            currentUser: false,
            allowModif: false,
            visibleModal: false,
            users: [],
            userQuery: "",
            waitResponse: true,
            user: null,
            error: false
        }
        this.toggle = this.toggle.bind(this)
        this.changeUserQuery = this.changeUserQuery.bind(this)
        this.toggleMember = this.toggleMember.bind(this)
        this.sendGroupCreationForm = this.sendGroupCreationForm.bind(this)
    }

    componentDidMount() {
        this.fetchProfile()
    }

    componentDidUpdate(prevProps, nextState) {
        if (prevProps.match.params.profileId !== this.props.match.params.profileId) this.fetchProfile()
        return null
    }

    fetchProfile() {
        const { profileId } = this.props.match.params
        if (Number(profileId) === this.props.user.user.id) {
            this.fetchUsers()
            this.setState({
                currentUser: true,
                user: this.props.user.user,
                waitResponse: false,
                allowModif: true
            })
        } else {
            axios.get(`${API_URL}users/${profileId}/`, {
                headers: {
                    Authorization: this.props.user.token
                }
            }).then(res => {
                this.setState({
                    user: res.data,
                    waitResponse: false,
                    allowModif: this.props.user.user.is_staff || this.props.user.user.in_bureau
                })
            }).catch(error => {
                console.log(error)
                this.setState({
                    waitResponse: false,
                    error: true
                })
            })
        }
    }

    fetchUsers() {
        // load all profiles in club
        axios.get(`${API_URL}users/`, {
            headers: {
                Authorization: this.props.user.token
            }
        }).then(res => {
            let users = []
            res.data.map((user) => {
                let selected = user.id === this.props.user.user.id
                users.push({
                    id: user.id,
                    name: user.name,
                    firstname: user.firstname,
                    username: user.username,
                    string: StringRenderer.userRenderer(user),
                    selected: selected
                })
                return 0
            })
            this.setState({
                users: users
            })
        }).catch(error => {
            console.log(error)
        })
    }

    toggle() {
        this.setState({
            visibleModal: !this.state.visibleModal
        });
        if (!this.state.visibleModal) {
            let newUser = this.state.users;
            newUser.map(user => {
                let selected = user.id === this.props.user.user.id;
                user.selected = selected;
                return 0
            })
            this.setState({
                users: newUser
            })
        }
    }

    toggleMember(memberId) {
        let newUsers = this.state.users
        let correspondingProfile = newUsers.find(user => {
            return user.id === memberId
        })

        correspondingProfile.selected = !correspondingProfile.selected;

        this.setState({
            users: newUsers
        })
    }

    changeUserQuery(newValue) {
        this.setState({
            "userQuery": newValue
        })
    }

    sendGroupCreationForm(name, description) {
        if (this.state.waitResponse) return -1
        let payload = BandSerializer.serializeNewBand(name, description, this.selectedMembers);
        this.setState({
            waitResponse: true
        })
        axios.post(`${API_URL}bands/`, payload,
            {
                headers: {
                    "Content-Type": 'application/json',
                    Authorization: this.props.user.token
                }
            }
        ).then(res => {
            this.setState({
                waitResponse: false
            })
            this.props.addBand(res.data);
            this.toggle()
        }).catch(error => {
            this.setState({
                waitResponse: false
            })
            console.log(error.response)
        })
    }

    get selectedMembers() {
        return this.state.users.filter(user => {
            return user.selected
        })
    }

    foundUsers() {
        let query = this.state.userQuery;
        let regex = new RegExp('^' + query, 'i');
        let foundMembers = this.state.users.filter(user => {
            return user.name.match(regex) || user.username.match(regex) || user.firstname.match(regex)
        });
        if (!query.trim()) return this.state.users;
        else return foundMembers
    }

    render() {
        const profileOptions = this.state.user ? (
            <ProfileWrapper email={this.state.user.email}
                currentUser={this.state.currentUser}
                visibleModal={this.state.visibleModal}
                toggle={this.toggle}
                groups={this.state.user.band_list}
                userDescription={this.state.user.description}
                users={this.state.users}
                userQuery={this.state.userQuery}
                foundUsers={this.foundUsers()}
                changeUserQuery={this.changeUserQuery}
                selectedMembers={this.selectedMembers}
                toggleMember={this.toggleMember}
                sendGroupCreationForm={this.sendGroupCreationForm}
                waitResponse={this.state.waitResponse}
                allowModif={this.state.allowModif}
                userName={StringRenderer.userRenderer(this.state.user)}/>
        ) : <Spinner2/>

        return (
            <div>
                {profileOptions}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    addBand: newBand => dispatch(addBand(newBand))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWrapperContainer);