import React, {Component} from "react";
import PropTypes from 'prop-types';

import './booking.scss'
import {BookingSlot, DateHelper, HoursBookingSlots} from "../../../utils/dateHelper";
import BookingSlotContainer from "./bookingSlotContainer";

class DayCol extends Component {

    renderBookingSlots() {
        let _this = this;
        return HoursBookingSlots.map((hourBookingSlot, index) => {
            return (
                <BookingSlotContainer key={index}
                                      bookingSlot={new BookingSlot(_this.props.currentDate, index)}/>
            )
        })
    }

    render() {
        const currentDay = DateHelper.sameDayDates(new Date(), this.props.currentDate) ? "today" : ""
        return (
            <div className={`day-col ${currentDay}`}>
                <div className="bookings-header">
                    <div className="day">
                        {DateHelper.renderDate(this.props.currentDate).limitedDay}
                    </div>
                    <div className="dateNumber">
                        {DateHelper.renderDate(this.props.currentDate).date}
                    </div>
                </div>
                <div className="bookings-content">
                    {this.renderBookingSlots()}
                </div>
            </div>
        );
    }
}

DayCol.propTypes = {
    currentDate: PropTypes.object
};

export default DayCol;