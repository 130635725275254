export default class Logger {

    /**
     * Log DRF response error after client request
     */
    static logDRFerror (error) {
        return error.response.data.detail
    }

    /**
     * Gives status of auth error
     */
    static getDRFErrorStatus (error) {
        return Logger.getStatus(error.response)
    }

    /**
     * Gives the response of an error
     */
    static getResponse (error) {
        return error.response
    }

    /**
     * Gives the status of a response
     */
    static getStatus (response) {
        return response.status
    }
}