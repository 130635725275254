import React, {Component} from "react";

import './admin.scss'
import {Col, Row} from "reactstrap";
import StringRenderer from "../../../utils/stringRenderer";
import Parser from "../../../utils/parsers";
import {DateHelper} from "../../../utils/dateHelper";
import Delete from "../../../../assets/images/delete.svg";
import Modify from "../../../../assets/images/modify.svg";
import Spinner1 from "../../generic/spinner/spinner1";
import EditRegularBookingForm from "./editRegularBookingForm";
import axios from "axios";
import {BOOKING_APP_URL} from "../../../../urls";
import {BookingSerializer} from "../../../utils/serializers/booking";
import {NavLink} from 'react-router-dom';

class RegularBooking extends Component {
    constructor() {
        super();
        this.state = {
            modifBooking: false,
            waitRequestBooking: false,
        };
        this.deleteBooking = this.deleteBooking.bind(this);
        this.updateBooking = this.updateBooking.bind(this);
        this.toggleModifBooking = this.toggleModifBooking.bind(this)
    }

    toggleModifBooking(event) {
        if (event) event.preventDefault();
        this.setState({
            modifBooking: !this.state.modifBooking
        })
    }

    deleteBooking(event) {
        event.preventDefault();
        let verif = window.confirm("/!\\ Êtes-vous sûr de supprimer le créneau régulier ? L'action est irréversible.");
        if (verif) this.props.deleteBooking(this.props.booking.id)
    }

    updateBooking(booking, bookTypeValue, dateBegin, dateEnd, hourBookingSlot) {
        let payload = BookingSerializer.serializePutRegularBookingSlot(bookTypeValue, dateBegin, dateEnd, hourBookingSlot);

        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };

        this.setState({
            waitRequestBooking: true
        });

        axios.put(`${BOOKING_APP_URL}regular/${booking.id}/`,payload, header).then(res => {
            this.setState({
                waitRequestBooking: false
            });

            this.toggleModifBooking()
        }).catch(error => {
            console.log(error.response);
            this.toggleModifBooking();
            this.setState({
                waitRequestBooking: false
            })
        });
    }

    render() {
        const actionsOptions = this.props.waitRequestBooking ? (
            <Spinner1/>
        ) : (
            <div>
                <button className="gray mr-5" title="Supprimer la réservation régulière"
                        onClick={this.deleteBooking}>
                    <img alt="Supprimer la réservation régulière" src={Delete}/>
                </button>
                <button title="Modifier la réservation" className="gray"
                        onClick={this.toggleModifBooking.bind(this)}><img alt="Modifier la réservation" src={Modify}/></button>
            </div>
        )

        const bookerInfo = this.props.booking.band ? 
            <NavLink to={`/band/${this.props.booking.band.id}/`}>{`${StringRenderer.bandRenderer(this.props.booking.band)} (Groupe)`}</NavLink> : 
            <NavLink to={`/profile/${this.props.booking.user.id}/`}>{`${StringRenderer.userRenderer(this.props.booking.user)} (Membre)`}</NavLink>;

        const renderOptions = this.state.modifBooking ? (
            <EditRegularBookingForm
                bookTypeValue={this.props.booking.book_type}
                bookDayOptions={this.props.bookDayOptions}
                bookTypeOptions={this.props.bookTypeOptions}
                bookingSlotOptions={this.props.bookingSlotOptions}
                toggleModifBooking={this.toggleModifBooking.bind(this)}
                waitRequestBooking={this.state.waitRequestBooking}
                updateBooking={this.updateBooking}
                booking={this.props.booking}/>
        ) : (
            <Row>
                <Col>
                    {this.props.booking.book_type}
                </Col>
                <Col>
                    {bookerInfo}
                </Col>
                <Col>
                    {DateHelper.getDayEquivalence(Parser.parseDjangoDate(this.props.booking.date_begin)).full}
                </Col>
                <Col>
                    {`${DateHelper.renderHour(Parser.parseDjangoDate(this.props.booking.date_begin))} à ${DateHelper.renderHour(Parser.parseDjangoDate(this.props.booking.date_end))}`}
                </Col>
                <Col>
                    Du {DateHelper.renderDate(Parser.parseDjangoDate(this.props.booking.date_begin)).limited} au {DateHelper.renderDate(Parser.parseDjangoDate(this.props.booking.date_end)).limited}
                </Col>
                <Col>
                    {actionsOptions}
                </Col>
            </Row>
        )

        return (
            <div className="regular-booking">
                {renderOptions}
            </div>
        );
    }
}

export default RegularBooking;