import React, {Component} from "react";

import {connect} from 'react-redux';
import axios from 'axios';

import './bands.scss'
import BandPage from './bandPage'
import {Container} from "reactstrap";
import {API_URL, BAND_APP_URL} from "../../../../urls";
import Spinner2 from '../../generic/spinner/spinner2';
import StringRenderer from '../../../utils/stringRenderer'

class Band extends Component {
    constructor() {
      super();
      this.state = {
        band: null,
        waiting: true,
        error: false,
        visibleModal: false,
        userQuery: '',
      }
      this.addMember = this.addMember.bind(this)
      this.toggle = this.toggle.bind(this)
      this.changeUserQuery = this.changeUserQuery.bind(this)
      this.removeBandMember = this.removeBandMember.bind(this)
    }

    componentDidMount() {
      const { bandId } = this.props.match.params
      axios.get(`${BAND_APP_URL}${bandId}/`, {
          headers: {
              Authorization: this.props.user.token
          }
      }).then(resBand => {
          // load all profiles in club
          axios.get(`${API_URL}users/`, {
              headers: {
                  Authorization: this.props.user.token
              }
          }).then(res => {
              let users = []
              res.data.map((user) => {
                  let selected = user.id === this.props.user.user.id
                  users.push({
                      id: user.id,
                      name: user.name,
                      firstname: user.firstname,
                      username: user.username,
                      string: StringRenderer.userRenderer(user),
                      selected: selected
                  })
                  return 0
              })
                this.setState({
                  band: resBand.data,
                  users: users,
                  waiting: false
              })
          }).catch(error => {
              console.log(error)
              this.setState({
                waiting: false,
                error: true
              })
          })
      }).catch(error => {
          console.log(error)
          this.setState({
            waiting: false,
            error: true
          })
      })
    }

    toggle () {
      this.setState({
        visibleModal: !this.state.visibleModal
      })
    }

    updateBand (updatedSerializedBand, toggle) {
      let header = {
        headers: {
            "Content-Type": 'application/json',
            Authorization: this.props.user.token
        }
      }
      
      axios.put(`${BAND_APP_URL}${updatedSerializedBand.id}/`, updatedSerializedBand, header).then(res => {
        console.log(res.data)
        this.setState({
            waitChangeUser: false,
            band: res.data,
            waiting: false
        })
        if (toggle) this.toggle()
      }).catch(error => {
          this.setState({
            error: true,
            waiting: false
          });
          console.log(error.response);
      })
    }

    addMember (memberId) {
      let member = this.state.users.find(user => {
        return user.id === memberId
      })
      this.setState({ waiting: true })
      let updatedBand = this.state.band
      updatedBand.members.push(member)
      updatedBand.members = updatedBand.members.map(member => {
        return member.id
      })
      this.updateBand(updatedBand, true)
    }
  
    removeBandMember (userId, event) {
      event.preventDefault();
      let memberIndex = this.state.users.findIndex(user => {
        return user.id === userId
      })
      this.setState({ waiting: true })
      let updatedBand = this.state.band
      updatedBand.members.splice(memberIndex)
      updatedBand.members = updatedBand.members.map(member => {
        return member.id
      })
      this.updateBand(updatedBand, false)
    }

    changeUserQuery(newValue) {
      this.setState({
          "userQuery": newValue
      })
    }

    foundUsers() {
      let query = this.state.userQuery;
      let regex = new RegExp('^' + query, 'i');
      let foundMembers = this.state.users.filter(user => {
          return user.name.match(regex) || user.username.match(regex) || user.firstname.match(regex)
      });
      if (!query.trim()) return this.state.users;
      else return foundMembers
  }


    render() {
      const renderingOptions  = this.state.waiting ? <Spinner2/> : (this.state.error ? <h1>Groupe non trouvé</h1> : 
        <BandPage 
          removeBandMember={this.removeBandMember}
          users={this.state.users}
          visibleModal={this.state.visibleModal}
          toggle={this.toggle}
          toggleMember={this.addMember}
          changeUserQuery={this.changeUserQuery}
          userQuery={this.state.userQuery}
          foundUsers={this.foundUsers()}
          band={this.state.band}/>)

    
      return (
        <Container fluid={true} className="band">
          {renderingOptions}
        </Container>
      );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Band);