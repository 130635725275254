import React, { Component } from "react";

import './notFound.scss'

class NotFound extends Component {

    render() {
        return (
            <div className="notFound">
                <h1>
                    Page Introuvable !
                </h1>
            </div>
        );
    }
}

export default NotFound;