/**
 * Action types
 */
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';

/**
 * Actions creator
 */
export function setScreenSize(innerWidth) {
    return { type: SET_SCREEN_SIZE, innerWidth: innerWidth }
}