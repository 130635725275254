import React, {Component} from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {connect} from 'react-redux'

import {NavLink} from 'react-router-dom';
import './profile.scss'
import FormGroupCreation from "./formGroupCreation";
import StringRenderer from "../../../utils/stringRenderer";

class ProfileWrapper extends Component {

    descriptionOptions() {
        return this.props.userDescription ? (
            <p>{this.props.userDescription}</p>
        ) : <button className="no-style">Ajoutez une petite description</button>
    }

    groups() {
        return this.props.groups.map((group, index) => {
            return <li key={index}>
                <h3><NavLink to={`/band/${group.id}/`}>{StringRenderer.bandRenderer(group)}</NavLink></h3>
            </li>
        })
    }

    render() {
        const groupOption = this.props.groups.length ? (
            <ul>
                {this.groups()}
            </ul>) : <span>Aucun groupe pour le moment</span>
        return (
            <div className="profile-wrapper">
                <div className="profile-header">
                    <div className="image-wrapper">
                        <img alt="Profil" src="https://upload.wikimedia.org/wikipedia/commons/3/39/Pat_metheny_orch2.jpg"/>
                    </div>
                    <div className="infos">
                        <h2>
                            {this.props.userName}
                        </h2>
                        {/*{this.descriptionOptions()}*/}
                    </div>
                </div>
                {/*<div className="contact">*/}
                    {/*<h2>*/}
                        {/*Contact*/}
                    {/*</h2>*/}
                    {/*{this.props.email}*/}
                {/*</div>*/}
                <div className="groups">
                    <div className="header-group">
                        <h2>
                            Groupes
                        </h2>
                        {!this.props.allowModif ? "" : (
                            <button onClick={this.props.toggle} className="no-style">
                            +
                            </button>
                        )}

                    </div>
                    <div className="group-list">
                        {groupOption}
                    </div>
                </div>
                {!this.props.allowModif ? "" : (
                <Modal className="modal-new-group" isOpen={this.props.visibleModal} toggle={this.props.toggle}>
                    <ModalHeader toggle={this.props.toggle}>Ajouter un groupe</ModalHeader>
                    <ModalBody>
                        <FormGroupCreation
                            users={this.props.users}
                            userQuery={this.props.userQuery}
                            changeUserQuery={this.props.changeUserQuery}
                            foundUsers={this.props.foundUsers}
                            toggle={this.props.toggle}
                            toggleMember={this.props.toggleMember}
                            selectedMembers={this.props.selectedMembers}
                            sendGroupCreationForm={this.props.sendGroupCreationForm}
                            waitResponse={this.props.waitResponse}
                            ref={this.refFormGroupCreation}/>
                    </ModalBody>
                </Modal>
                )}

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(ProfileWrapper);