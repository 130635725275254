import React, {Component} from "react";

import './admin.scss'
import AdminGenericSection from "./adminGenericSection";
import {connect} from "react-redux";
import AdminGenericAction from "./adminGenericAction";
import {Col, Row} from "reactstrap";
import axios from "axios";
import {BAND_APP_URL, BOOKING_APP_URL, USERS_URL} from "../../../../urls";
import RegularBooking from "./regularBooking";
import RegularBookingForm from "./regularBookingForm";
import {BookingTypes} from "../booking/booking";
import {DateHelper, dayEquivalences, HoursBookingSlots} from "../../../utils/dateHelper";
import Parser from "../../../utils/parsers";
import {BookingSerializer} from "../../../utils/serializers/booking";

// TODO : plus tard, vérifier unicité d'un nouveau créneau régulier

export class SelectedBooker {
    type: string;
    booker: any;

    static MEMBER(): string {
        return "member";
    }

    static BAND(): string {
        return "band;"
    }

    getId() {
        return this.booker.id
    }

    constructor(type: string, booker) {
        this.type = type;
        this.booker = booker;
    }

    static createSelectedBand(booker): SelectedBooker {
        return new SelectedBooker(this.BAND(), booker)
    }

    static createSelectedMember(booker): SelectedBooker {
        return new SelectedBooker(this.MEMBER(), booker)
    }

    isBand(): boolean {
        return this.type === SelectedBooker.BAND()
    }
}

type State = {
    selectedBooker: SelectedBooker,
    bookDayValue: any,
    bookingSlotValue: any,
    dateBegin: Date,
    dateEnd: Date
}

class BookingSection extends Component<State> {
    constructor() {
        super();
        this.state = {
            regularBookings: [],
            bookType: BookingTypes['training'],
            bookerQuery: "",
            selectedBooker: null,
            day: null,
            bookingSlotValue: this.bookingSlotOptions()[0],
            foundBands: [],
            foundMembers: [],
            bookDayValue: this.bookDayOptions()[this.getNextAvailableDate().getDay() - 1],
            dateBegin: this.getNextAvailableDate(),
            dateEnd: DateHelper.addDays(this.getNextAvailableDate(), 365),
            dateBeginMin: this.getNextAvailableDate(),
            dateBeginMax: DateHelper.addDays(this.getNextAvailableDate(), 365),
            dateEndMin: DateHelper.addDays(this.getNextAvailableDate(), 14),
            dateEndMax: DateHelper.addDays(this.getNextAvailableDate(), 365),
            waitSend: false,
            waitRequestBooking: false,
        };
        this.bands = [];
        this.members = [];
        this.handleChangeBookType = this.handleChangeBookType.bind(this);
        this.handleChangeBookerQuery = this.handleChangeBookerQuery.bind(this);
        this.setBooker = this.setBooker.bind(this);
        this.handleChangeBookDay = this.handleChangeBookDay.bind(this);
        this.handleChangeBookingSlot = this.handleChangeBookingSlot.bind(this);
        this.handleChangeDateBegin = this.handleChangeDateBegin.bind(this);
        this.handleChangeDateEnd = this.handleChangeDateEnd.bind(this);
        this.sendRegularBooking = this.sendRegularBooking.bind(this);
        this.deleteBooking = this.deleteBooking.bind(this)
    }

    getNextAvailableDate() {
        // test if holidays
        let date = new Date()
        if (date.getDay() === 0) DateHelper.addDays(date, 1);
        return date
    }

    componentWillMount() {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        axios.get(`${BOOKING_APP_URL}regular/`, header).then(res => {
            this.setState({
                regularBookings: res.data
            })
        }).catch(error => {
            console.log(error.response);
        });
        this.getBands();
        this.getMembers();
    }

    getBands() {
        let header = {
            headers: {
                Authorization: this.props.user.token
            }
        };
        axios.get(BAND_APP_URL, header).then(res => {
            this.bands = res.data
        })
            .catch(error => {
                console.log(error.response)
            })
    }

    getMembers() {
        let header = {
            headers: {
                Authorization: this.props.user.token
            }
        };
        axios.get(USERS_URL, header).then(res => {
            this.members = res.data
        })
            .catch(error => {
                console.log(error.response)
            })
    }

    bookTypes() {
        let bookTypesArray = Object.keys(BookingTypes).map(function (k) {
            let el = BookingTypes[k];
            el.id = el.serializationKey;
            return el
        });
        bookTypesArray.splice(0, 1);
        return bookTypesArray
    }

    handleChangeBookType(newValue) {
        this.setState({
            bookType: newValue
        })
    }

    handleChangeBookerQuery(newValue) {
        this.setState({
            bookerQuery: newValue
        })
    }

    handleChangeBookDay(bookDay) {
        this.setState({
            bookDayValue: bookDay,
            dateBegin: DateHelper.getNearestNextDayDate(this.state.dateBegin, bookDay.day)
        })
    }

    searchedBands() {
        let query = this.state.bookerQuery;
        let regex = new RegExp(query, 'i');
        return this.bands.filter(band => {
            return band.name.match(regex)
        });
    }

    searchedMembers() {
        let query = this.state.bookerQuery;
        let regex = new RegExp(query, 'i');
        return this.members.filter(user => {
            return user.name.match(regex) || user.username.match(regex) || user.firstname.match(regex)
        });
    }

    setBooker(booker) {
        this.setState({
            selectedBooker: booker
        })
    }

    bookDayOptions() {
        let effectiveDays = [...dayEquivalences];
        effectiveDays.splice(0, 1);
        return effectiveDays.map((day, index) => {
            return {
                day: index + 1,
                id: day.full,
                string: day.full
            }
        })
    }

    handleChangeBookingSlot(bookingSlot) {
        this.setState({
            bookingSlotValue: bookingSlot
        })
    }

    bookingSlotOptions() {
        return HoursBookingSlots.map((hourBookingSlot, index) => {
            return {
                id: index,
                bookingSlot: hourBookingSlot,
                string: hourBookingSlot.renderHourBookingSlot().string
            }
        })
    }

    handleChangeDateBegin(date) {
        let reset = !date;
        let parsedDate;
        if (reset) parsedDate = new Date();
        else parsedDate = Parser.parseLimitedDashDate(date);
        if (parsedDate.getDay() === 0) DateHelper.addDays(parsedDate, 1);
        let correspondingDay = this.bookDayOptions().find(day => {
            return parsedDate.getDay() === day.day
        })

        this.setState({
            bookDayValue: correspondingDay,
            dateBegin: parsedDate,
            dateEnd: DateHelper.copyAddDays(parsedDate, 365),
            dateEndMin: DateHelper.copyAddDays(parsedDate, 14),
            dateEndMax: DateHelper.copyAddDays(parsedDate, 365),
        })
    }

    handleChangeDateEnd(date) {
        let reset = !date;
        let parsedDate;
        if (reset) parsedDate = this.state.dateEndMax;
        else parsedDate = Parser.parseLimitedDashDate(date);
        this.setState({
            dateEnd: parsedDate
        })
    }

    sendRegularBooking(event) {
        event.preventDefault();
        let booker = this.state.selectedBooker;
        let checkForm = !!booker;
        if (!checkForm || this.state.waitSend) return -1;


        let book_type = this.state.bookType.serializationKey;
        let dateBegin: Date = this.state.dateBegin;

        let dateEnd: Date = DateHelper.getNearestNextDayDate(this.state.dateEnd, this.state.bookDayValue.day);
        let hourBookinglot = this.state.bookingSlotValue.bookingSlot;

        let payload = BookingSerializer.serializeRegularBookingSlot(book_type, booker, dateBegin, dateEnd, hourBookinglot);


        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };

        this.setState({
            waitSend: true,
        });
        axios.post(`${BOOKING_APP_URL}regular/`, payload, header).then(res => {
            let newBookings = this.state.regularBookings;
            newBookings.push(res.data);
            this.setState({
                regularBookings: newBookings,
                bookType: BookingTypes['training'],
                bookerQuery: "",
                selectedBooker: null,
                day: null,
                bookingSlotValue: this.bookingSlotOptions()[0],
                foundBands: [],
                foundMembers: [],
                bookDayValue: this.bookDayOptions()[this.getNextAvailableDate().getDay() - 1],
                dateBegin: this.getNextAvailableDate(),
                dateEnd: DateHelper.addDays(this.getNextAvailableDate(), 365),
                dateBeginMin: this.getNextAvailableDate(),
                dateBeginMax: DateHelper.addDays(this.getNextAvailableDate(), 365),
                dateEndMin: DateHelper.addDays(this.getNextAvailableDate(), 14),
                dateEndMax: DateHelper.addDays(this.getNextAvailableDate(), 365),
                waitSend: false,
                waitRequestBooking: false,
            });
        }).catch(error => {
            this.setState({
                waitSend: false,
            });
            console.log(error.response)
        })
    }

    canSubmit() {
        return !this.state.waitSend
    }

    deleteBooking(bookingId) {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        this.setState({
            waitRequestBooking: true,
        });
        axios.delete(`${BOOKING_APP_URL}regular/${bookingId}/`, header).then(res => {
            this.setState({
                waitRequestBooking: false
            });
            let bookingIndex = this.state.regularBookings.findIndex(booking => {
                return booking.id === bookingId
            });
            let newBookingState = [...this.state.regularBookings];
            newBookingState.splice(bookingIndex, 1);
            this.setState({
                regularBookings: newBookingState
            })
        }).catch(error => {
            this.setState({
                waitRequestBooking: false
            })
            console.log(error.response)
        })
    }

    render() {
        const renderRegularBookings = this.state.regularBookings.map((booking, index) => {
            return <RegularBooking
                booking={booking}
                bookDayOptions={this.bookDayOptions()}
                bookingSlotOptions={this.bookingSlotOptions()}
                bookTypeOptions={this.bookTypes()}
                user={this.props.user}
                waitRequestBooking={this.state.waitRequestBooking}
                deleteBooking={this.deleteBooking}
                key={index}/>
        });

        return (
            <AdminGenericSection title="Créneaux">
                <AdminGenericAction title="Ajouter un créneau régulier">
                    <RegularBookingForm
                        foundBands={this.searchedBands()}
                        foundMembers={this.searchedMembers()}
                        user={this.props.user}
                        setBooker={this.setBooker}
                        selectedBooker={this.state.selectedBooker}
                        handleChangeBookerQuery={this.handleChangeBookerQuery}
                        bookerQuery={this.state.bookerQuery}
                        bookTypeValue={this.state.bookType}
                        bookDayValue={this.state.bookDayValue}
                        bookDayOptions={this.bookDayOptions()}
                        handleChangeBookDay={this.handleChangeBookDay}
                        handleChangeBookType={this.handleChangeBookType}
                        bookingSlotOptions={this.bookingSlotOptions()}
                        handleChangeBookingSlot={this.handleChangeBookingSlot}
                        bookingSlotValue={this.state.bookingSlotValue}
                        dateBegin={DateHelper.renderDate(this.state.dateBegin).limitedDash}
                        dateEnd={DateHelper.renderDate(this.state.dateEnd).limitedDash}
                        dateBeginMin={DateHelper.renderDate(this.state.dateBeginMin).limitedDash}
                        dateBeginMax={DateHelper.renderDate(this.state.dateBeginMax).limitedDash}
                        dateEndMin={DateHelper.renderDate(this.state.dateEndMin).limitedDash}
                        dateEndMax={DateHelper.renderDate(this.state.dateEndMax).limitedDash}
                        handleChangeDateBegin={this.handleChangeDateBegin}
                        handleChangeDateEnd={this.handleChangeDateEnd}
                        sendRegularBooking={this.sendRegularBooking}
                        canSubmit={this.canSubmit()}
                        bookTypeOptions={this.bookTypes()}/>
                </AdminGenericAction>
                <AdminGenericAction title="Liste des créneaux réguliers">
                    <Row className="headerBookings">
                        <Col>
                            Type
                        </Col>
                        <Col>
                            Responsable
                        </Col>
                        <Col>
                            Jour
                        </Col>
                        <Col>
                            Créneau
                        </Col>
                        <Col>
                            Période
                        </Col>
                        <Col>
                            Actions
                        </Col>
                    </Row>
                    {renderRegularBookings}
                </AdminGenericAction>
            </AdminGenericSection>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(BookingSection);
