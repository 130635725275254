import React, { Component } from "react";
import axios from 'axios';

import { withCookies } from 'react-cookie';
import { Link } from "react-router-dom";

// redux
import { connect } from 'react-redux'
import { setUser, removeUser } from '../../redux/actions/index'

import '../../../sass/main.scss'

import './connexion.scss'
import User from "../../classes/user";
import Logger from "../../utils/logger";
import DRFAuthHelper from "../../utils/drfAuthHelper";
import FormValidator from "../../utils/formValidator";
import { BAD_REQUEST } from "../../utils/status";
import { API_URL, REQUEST_USER_VERIF, USERS_URL } from "../../../urls";
import Spinner1 from "../generic/spinner/spinner1";

class Connexion extends Component {
    constructor() {
        super();
        this.state = {
            login: "",
            password: "",
            invalidFields: [],
            alreadySubmitTry: false,
            waiting: false,
            errorLogin: false
        }

        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Checks if the login form is correct before sending
     */
    checkForm() {
        // Re-initialize form validation
        const newValidation = []
        const stateFields = ['login', 'password']
        stateFields.map((field) => {
            if (FormValidator.fieldEmpty(this.refs[field].value)) {
                newValidation.push(field)
            }
            return 0
        })
        this.setState({
            invalidFields: newValidation
        })
        return newValidation
    }

    logItIn() {
        // const { cookies } = this.props;
        // cookies.set('user', user, { path: '/' });
        const { cookies } = this.props;
        this.setState({ waiting: true })
        axios.post(`${API_URL}rest-auth/login/`, {
            username: this.state.login,
            password: this.state.password
        })
            .then(res => {
                // Catch the current token of the session user
                var token = DRFAuthHelper.getToken(res)

                // Get the full profile
                axios.get(`${API_URL}rest-auth/user/`, {
                    headers: {
                        Authorization: token
                    }
                }).then(res => {
                    // Set the user in cookie and redux state
                    // this.setState({ waiting: false })
                    cookies.set("token", token, { path: '/' })
                    this.props.setUser(new User(DRFAuthHelper.getData(res), token))

                    // if not waiting confirmation user and still not confirmed, ask request and redirect
                    if (!res.data.verified && !res.data.verificationSent) {
                        axios.get(`${REQUEST_USER_VERIF}`, {
                            params: {
                                username: res.data.username
                            },
                            headers: {
                                Authorization: token
                            }
                        }).then(res => {
                            this.props.setUser(new User(DRFAuthHelper.getData(res), token))
                        }).catch(error => {
                            console.log(Logger.logDRFerror(error))
                        })
                    }
                    axios.get(`${USERS_URL}registerVisit/`, { headers: { Authorization: token }}).then(res => {
                        
                    }).catch(error => {
                        console.log(Logger.logDRFerror(error))
                    })

                }).catch(error => {
                    console.log(Logger.logDRFerror(error))
                })
            }).catch(error => {
                this.setState({ waiting: false })
                if (Logger.getDRFErrorStatus(error) === BAD_REQUEST) {
                    this.setState({
                        errorLogin: true
                    })
                    this.setState({
                        invalidFields: ['login', 'password']
                    })
                }
            });
    }

    handleChangeLogin(event) {
        this.setState({ login: event.target.value });
        if (this.state.alreadySubmitTry) this.checkForm();
    }

    handleChangePassword(event) {
        this.setState({ password: event.target.value });
        if (this.state.alreadySubmitTry) this.checkForm();
    }

    handleSubmit(event) {
        this.setState({
            alreadySubmitTry: true
        })
        let newValidation = this.checkForm();
        if (!newValidation.length) {
            this.logItIn();
        }
        event.preventDefault();
    }

    render() {
        const buttonOptions = this.state.waiting ? <Spinner1/> : "Connexion"
        return (
            <div className="connexion">
                <form id="connexionForm" onSubmit={this.handleSubmit}>
                    <input ref="login" className={this.state.invalidFields.includes("login") ? "invalid" : ''} type="text" placeholder="Login INP-net" onChange={this.handleChangeLogin}></input>
                    <input ref="password" className={this.state.invalidFields.includes("password") ? "invalid" : ''} type="password" placeholder="Mot de passe" onChange={this.handleChangePassword}></input>
                    <div className="buttonWrapper">
                        <button className={this.state.waiting ? "disabled" : ""} type="submit">{buttonOptions}</button>
                    </div>
                </form>
                <div className="subWrapper">
                    <span className={`error ${this.state.errorLogin ? "active" : ""} `}>Identifiants incorrects</span>
                    <Link to={"/forgottenPassword"}>mot de passe oublié ?</Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user)),
    removeUser: () => dispatch(removeUser())
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Connexion));