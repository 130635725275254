import React, {Component} from "react";

import './admin.scss'
import AdminGenericSection from "./adminGenericSection";
import AdminGenericAction from "./adminGenericAction";
import FormValidator from "../../../utils/formValidator";
import NewUserForm from "./newUserForm";
import UserConfirmation from "./userConfirmation";
import UserSerializer from "../../../utils/serializers/user";

import axios from 'axios';
import { AUTH_URL, USERS_URL} from "../../../../urls";
import {connect} from "react-redux";
import Member from "./Member";
import {Col, Row} from "reactstrap";

class UserSection extends Component {
    constructor() {
        super();
        this.state = {
            newUsername: "",
            newUserEmail: "",
            newUserFirstname: "",
            newUserLastname: "",
            invalidFields: "",
            isAdminValue: {
                id: false,
                string: "non",
            },
            alreadySubmitTry: false,
            waitSend: false,
            unmounted: false,
            users: [],
            queryMember: "",
        };
        this.refUserForm = React.createRef();
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangeUserFirstname = this.handleChangeUserFirstname.bind(this);
        this.handleChangeUserLastname = this.handleChangeUserLastname.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeAdmin = this.handleChangeAdmin.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteMember = this.deleteMember.bind(this);
        this.handleChangeQueryMember = this.handleChangeQueryMember.bind(this);
    }

    componentWillUnmount() {
        this.setState({
            unmounted: true
        })
    }

    handleChangeUserName(event) {
        event.preventDefault();
        this.setState({
            newUsername: event.target.value
        })
        if (this.state.alreadySubmitTry) this.checkForm();
    }

    handleChangeUserFirstname(event) {
        event.preventDefault();
        this.setState({
            newUserFirstname: event.target.value
        })
        if (this.state.alreadySubmitTry) this.checkForm();
    }

    handleChangeUserLastname(event) {
        event.preventDefault();
        this.setState({
            newUserLastname: event.target.value
        })
        if (this.state.alreadySubmitTry) this.checkForm();
    }

    handleChangeEmail(event) {
        event.preventDefault();
        this.setState({
            newUserEmail: event.target.value
        })
        if (this.state.alreadySubmitTry) this.checkForm();
    }

    handleChangeAdmin(option) {
        this.setState({
            isAdminValue: option
        })
    }

    reinitializeForm() {
        this.setState({
            newUsername: "",
            newUserEmail: "",
            newUserFirstname: "",
            newUserLastname: "",
            invalidFields: "",
            isAdminValue: {
                id: false,
                string: "non",
            },
            alreadySubmitTry: false,
        })
    }

    addUser(event) {
        event.preventDefault();
        if (this.state.waitSend) return;
        let errors = this.checkForm();
        if (!errors.length) {
            this.setState({
                waitSend: true,
            });
            let payload = UserSerializer.serializeCreateUser(this.state.newUsername, this.state.isAdminValue.id, this.state.newUserEmail, this.state.newUserLastname, this.state.newUserFirstname);
            let header = {
                Authorization: this.props.user.token
            };
            axios.post(`${AUTH_URL}registration/`, payload, header).then(res => {
                axios.get(`${USERS_URL}search/`, {
                    params: {
                        username: this.state.newUsername,
                    },
                    headers: header
                }).then(res => {
                    let header = {
                        headers: {
                            "Content-Type": 'application/json',
                            Authorization: this.props.user.token
                        }
                    };
                    let payload = UserSerializer.updateUserFromAdmin(res.data.id, this.state.newUsername, this.state.isAdminValue.id, this.state.newUserEmail, this.state.newUserLastname, this.state.newUserFirstname, true);
                    axios.put(`${USERS_URL}${res.data.id}/`, payload, header).then(res => {
                        let newUsersState = this.state.users;
                        newUsersState.push(res.data);
                        document.getElementById("new-user-form").reset();
                        this.setState({
                            waitSend: false,
                            users: newUsersState
                        });
                        this.reinitializeForm();
                    }).catch(error => {
                        this.setState({
                            waitSend: false
                        });
                        console.log(error.response);
                    });
                }).catch(error => {
                    this.setState({
                        waitSend: false
                    });
                    console.log(error.response);
                });
            }).catch(error => {
                // Success if error 500... TODO later
                    // set all infos
                    this.setState({
                        waitSend: false
                    });
                    console.log(error.response);
            })
        }
    }

    /**
     * Checks if the register form is correct before sending
     */
    checkForm() {
        // Re-initialize form validation
        const newValidation = [];
        const stateFields = ['username', 'userFirstname', 'userLastname', 'userEmail'];

        stateFields.map((field) => {
            if (FormValidator.fieldEmpty(this.refUserForm.current[field].current.value)) {
                newValidation.push(field)
            }
            return 0
        })
        this.setState({
            invalidFields: newValidation,
            alreadySubmitTry: true,
        })
        return newValidation
    }

    optionsAdmin() {
        return [
            {
                id: false,
                string: "utilisateur",
            },
            {
                id: true,
                string: "administrateur",
            }

        ]
    }

    componentWillMount() {
        this.getUsers();
    }

    getUsers() {
        let header = {
            Authorization: this.props.user.token
        };
        axios.get(`${USERS_URL}full/`, {headers: header}).then(res => {
            this.setState({
                users: res.data
            });
        }).catch(error => {
            console.log(error.response);
        })
    }

    deleteMember(id) {
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: this.props.user.token
            }
        };
        axios.delete(`${USERS_URL}${id}/`, header).then(res => {
            let newMembersState = this.state.users.filter(user => {
                return user.id !== id
            })
            this.setState({
                users: newMembersState
            })
        }).catch(error => {
            console.log(error.response);
        })
    }

    handleChangeQueryMember(event) {
        event.preventDefault();
        this.setState({
            queryMember: event.target.value
        })
    }

    searchedMembers() {
        let query = this.state.queryMember;
        let regex = new RegExp(query, 'i');
        let foundMembers = this.state.users.filter(user => {
            return user.name.match(regex) || user.username.match(regex) || user.firstname.match(regex)
        })
        if (!query.trim()) return this.state.users
        else return foundMembers
    }

    waitingConfirmationMembers() {
        let users = this.state.users.filter(user => {
            return !user.verified && user.waitingVerification
        }).map((user, index) => {
            return <UserConfirmation key={index} member={user} getUsers={this.getUsers.bind(this)}/>
        })
        return users
    }

    updateMember(member) {
        let newMembersState = this.state.users
        let correspondingMemberIndex = this.state.users.findIndex(user => {
            return user.id === member.id
        })
        newMembersState[correspondingMemberIndex] = member;
        this.setState({
            users: newMembersState
        })
    }

    render() {
        const members = this.searchedMembers().sort((memberA, memberB) => {
            let res
            if (memberA.last_visit == null || memberA.last_visit === undefined) {
                res =  1
            } else if (memberB.last_visit == null || memberB.last_visit === undefined) {
                res =  -1
            } else {
                res =  new Date(memberB.last_visit) - new Date(memberA.last_visit)
            }
            return res
        }).map((member, index) => {
            return <Member deleteMember={this.deleteMember}
                           user={this.props.user}
                           updateMember={this.updateMember.bind(this)}
                           key={index} member={member}/>
        });

        const confirmMemberOptions = this.waitingConfirmationMembers().length ? 
                <table className="user-conf-panel">
                <tbody>
                    <tr>
                        <th>
                            Nom d'utilisateur
                        </th>
                        <th>
                            Nom
                        </th>
                        <th>
                            Prénom
                        </th>
                        <th>
                            Confirmer
                        </th>
                    </tr>
                    {this.waitingConfirmationMembers()}
                </tbody>
            </table> : (
                <table>
                    <tbody>
                        <tr>
                            <td>
                                Aucun utilisateur à vérifier
                            </td>
                        </tr>
                    </tbody>
                </table>
            )

        return (
            <AdminGenericSection title="Membres">
                <AdminGenericAction title="Ajouter un membre">
                    <NewUserForm
                        newUsername={this.props.newUsername}
                        newUserEmail={this.props.newUserEmail}
                        newUserFirstname={this.props.newUserFirstname}
                        newUserLastname={this.props.newUserLastname}
                        ref={this.refUserForm}
                        handleChangeUserName={this.handleChangeUserName}
                        handleChangeUserFirstname={this.handleChangeUserFirstname}
                        handleChangeUserLastname={this.handleChangeUserLastname}
                        handleChangeEmail={this.handleChangeEmail}
                        handleChangeAdmin={this.handleChangeAdmin}
                        isAdminValue={this.state.isAdminValue}
                        optionsAdmin={this.optionsAdmin()}
                        addUser={this.addUser}
                        canSubmit={!this.state.waitSend}
                        invalidFields={this.state.invalidFields}/>
                </AdminGenericAction>
                <AdminGenericAction title="Nouveaux membres à vérifier">
                    {confirmMemberOptions}
                </AdminGenericAction>
                <AdminGenericAction title="Liste des membres">
                    <Row className="input-search-member">
                        <Col>
                            <input value={this.state.queryMember}
                                   onChange={this.handleChangeQueryMember}
                                   placeholder="Cherchez un membre"/>
                        </Col>
                    </Row>
                    <table>
                        <tbody>
                        <tr className="headerMembers">
                            <th>
                                Nom d'utilisateur
                            </th>
                            <th>
                                Dernière visite
                            </th>
                            <th>
                                Nom
                            </th>
                            <th>
                                Prénom
                            </th>
                            <th>
                                E-mail
                            </th>
                            <th>
                                Vérifié
                            </th>
                            <th>
                                Statut
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                        {members}
                        </tbody>
                    </table>
                </AdminGenericAction>
            </AdminGenericSection>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(UserSection);
