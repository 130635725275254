import React, { Component } from "react";

import { Router } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import { store } from './js/redux/store'

import MainContainer from './js/components/mainContainer/mainContainer';

import { CookiesProvider } from 'react-cookie';

const createHistory = require('history');


class Root extends Component {
    render() {
        const history = createHistory.createBrowserHistory();
        return (
            <CookiesProvider>
                <Provider store={store}>
                    <Router history={history}>
                        <MainContainer />
                    </Router>
                </Provider>
            </CookiesProvider>
        );
    }
}

export default Root;