import React, { Component } from "react";
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { withRouter } from 'react-router-dom';

// components
import ProfileBar from './profileBar/profileBar'

import LogoLimited2 from '../../../assets/images/logo_club_ltd_2.svg'
import MenuIcon from '../../../assets/images/menu.svg'
import SideBar from '../sidebar/sidebar'

import './header.scss';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuActivated: false
        }
    }

    desactivateMenu() {
        this.setState({
            menuActivated: false
        })
    }

    activateMenu() {
        this.setState({
            menuActivated: true
        })
    }

    componentDidUpdate(prevProps) {
        let previousPage = prevProps.location
        if (previousPage !== this.props.location) {
            this.setState({
                menuActivated: false
            })
        }
    }

    render() {
        const mobileMode = this.props.screenManager.isSmall
        const welcomeLink = <NavLink className="no-style link-header-welcome" to="/">
                        <img alt="Logo" src={LogoLimited2}/>
                    </NavLink>
        const menuButton = <button onClick={this.activateMenu.bind(this)} id="menu-button" className="no-style">
            <img alt="menu-icon" src={MenuIcon}/>
        </button>

        return (
            <div className={`header ${mobileMode ? "mobileMode" : this.props.screenManager.isNotXl ? "limited" : ""} ${!this.props.user ? "invisible" : ""}`}>
                {mobileMode ? welcomeLink : <span></span>}
                <div className="access-div">
                    <ReactCSSTransitionGroup
                    transitionName="TransFade"
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}>
                        
                    {mobileMode & !this.state.menuActivated ? <div key="1">{menuButton}</div> : null}
                    
                    </ReactCSSTransitionGroup>
                    {mobileMode ? <SideBar active={this.state.menuActivated} desactivateMenu={this.desactivateMenu.bind(this)} screenManager={this.props.screenManager} user={this.props.user}/> : null}
                    {this.props.user ? <ProfileBar/> : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default withRouter(connect(mapStateToProps)(Header));