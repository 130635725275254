import React, { Component } from "react";

import './events.scss'

class Events extends Component {

    render() {
        return (
            <div className="events">
                events
            </div>
        );
    }
}

export default Events;