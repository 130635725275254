import React, { Component } from "react";

import './userParams.scss'

class UserParamGenericSection extends Component {

    render() {
        return (
            <div className="user-param-generic-section">
                <h2 className="title">
                    {this.props.title}
                </h2>
                <div className="section-content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default UserParamGenericSection;