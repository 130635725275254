import React, {Component} from "react";
import {connect} from 'react-redux'

import Logo from '../../../assets/images/logo_club.svg'

import BookingPanel from './bookingPanel/bookingPanel'
import WaitingConfirmationPanel from './WaitingConfirmationPanel'
import './home.scss'
import Connexion from "../connexion/connexion";
import Spinner1 from '../generic/spinner/spinner1';

class Home extends Component {

    render() {
        const bookOptions = this.props.user ? (this.props.user.user.verified ? <BookingPanel/> :
            this.props.user.user.waitingVerification ? <WaitingConfirmationPanel/> : <Spinner1 className="wait-confirmation"/>) : 
                <div className="welcome-view">
                    <img alt="Logo du club Musique" src={Logo}/>
                    <Connexion/>
                </div>
        return (
            <div className="home">
                {bookOptions}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Home);