import React, { Component } from "react";
import { Popover, PopoverBody } from 'reactstrap';

import axios from 'axios';

import { withCookies } from 'react-cookie';

import './profileBar.scss';
import '../../../../sass/main.scss'

// redux
import { connect } from 'react-redux'
import { removeUser } from '../../../redux/actions'

import StringRenderer from "../../../utils/stringRenderer";
import {API_URL} from "../../../../urls";
import {Link} from "react-router-dom";

class ProfileBar extends Component {
    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };
    }

    logout() {
        const { cookies } = this.props
        // Get the full profile
        axios.post(`${API_URL}rest-auth/logout/`, {
            headers: {
                Authorization: this.props.user.token
            }
        }).then(res => {
            // // Remove the user in cookie and redux state
            cookies.remove('token', { path: "/" });
            this.props.removeUser()
            document.location.href = "/";
        }).catch(error => {
            console.log(error.response)
        })
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        return (
            <div className="profileBar">
                <button id="PopoverProfile" className="no-style profileButton" onClick={this.toggle}>
                    <img alt="Profil" src="https://upload.wikimedia.org/wikipedia/commons/3/39/Pat_metheny_orch2.jpg">

                    </img>
                </button>
                <Popover className="profileBar" placement="bottom" isOpen={this.state.popoverOpen} target="PopoverProfile" toggle={this.toggle}>
                    <PopoverBody>
                        <ul className="profileAccess">
                            <li>
                                {StringRenderer.userRenderer(this.props.user.user)}
                            </li>
                        </ul>
                        <ul className="navItem">
                            <li>
                                <Link to="/user-params">
                                    <button className="no-style backgrounded-hover">Paramètres</button>
                                </Link>
                            </li>
                            <li>
                                <button className="no-style backgrounded-hover red" onClick={() => this.logout()}>Déconnexion</button>
                            </li>
                        </ul>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    removeUser: () => dispatch(removeUser())
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(ProfileBar));
