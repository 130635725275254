import React, {Component} from "react";

import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import './bands.scss'
import {Row, Col} from "reactstrap";
import StringRenderer from '../../../utils/stringRenderer';

class BandMember extends Component {

  render() {
    return (
      <Row>
        <Col>
          <div className="band-member pb-3 pt-3">
            <strong>
              <NavLink to={`/profile/${this.props.bandMember.id}/`}>{StringRenderer.userRenderer(this.props.bandMember)}</NavLink>
            </strong>
            {(this.props.user.user.is_staff || this.props.user.user.in_bureau || this.props.isInBand) ? 
              <button className="abord ml-3" onClick={evt => this.props.removeBandMember(this.props.bandMember.id, evt)}>Retirer</button> : ""}
          </div>

        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(BandMember);