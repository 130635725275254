import React, { Component } from "react";

import "./spinners.scss"

class Spinner1 extends Component {

    render() {
        return (
            <div className="spinner-1"></div>
        );
    }
}

export default Spinner1;