import React, {Component} from "react";

import {connect} from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import './bands.scss'
import {Row, Col} from "reactstrap";
import BandMember from './bandMember';
import FormAddMember from './formAddMember.js'
const bandImg = "https://www.cfbands.com/uploads/1/3/6/9/13694168/2610451_orig.jpg"

class BandPage extends Component {

  isInBand() {
    return this.props.band.members.find(member => {
      return member.id === this.props.user.user.id
    })
  }

  canModifyBand () {
    return this.isInBand() || this.props.user.user.is_staff || this.props.user.user.in_bureau
  }

  bandMembers () {
    return this.props.band.members.map((user, index) => {
      return (
        <BandMember key={index} isInBand={this.isInBand()} removeBandMember={this.props.removeBandMember} bandMember={user}/>
      )
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <h2>
              <div className="band-image">
                <img alt={`${this.props.band.name}`} src={bandImg}/>
              </div>
              {this.props.band.name}
            </h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h4 className="mb-3">
              Membres
              {
                this.canModifyBand ? (
                  <button className="ml-3 no-style add-button" onClick={this.props.toggle}>
                    +
                  </button>
                ) : ''
              }

              <Modal className="modal-new-member" isOpen={this.props.visibleModal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Ajouter un membre</ModalHeader>
                <ModalBody>
                  <FormAddMember
                    bandMembers={this.props.band.members}
                    changeUserQuery={this.props.changeUserQuery}
                    userQuery={this.props.userQuery}
                    toggleMember={this.props.toggleMember}
                    toggle={this.props.toggle}
                    foundUsers={this.props.foundUsers}
                    users={this.props.users}/>
                </ModalBody>
              </Modal>
            </h4>
            <div>
              {this.bandMembers()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(BandPage);