import { SET_USER, REMOVE_USER, ADD_BAND } from '../actions'

const initialState = null

const user = (state=initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return action.user
        case REMOVE_USER:
            return null
        case ADD_BAND:
            state.user.band_list.push(action.newBand);
            return state
        default:
            return state;
    }
}

export default user