import React, {Component} from "react";

import './userParams.scss'
import connect from "react-redux/es/connect/connect";
import AccountParams from "./accountParams";

class UserParams extends Component {

    render() {

        return (
            <div className="user-params">
                <AccountParams/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(UserParams);